import { decriptData, encriptData } from "../utils";
import api from "./api";

const getProduct = async (code) => {
  try {
    const sessionProducts = sessionStorage.getItem("p");
    if (sessionProducts) {
      const products = decriptData(sessionProducts);
      if (!code) return products;

      const foundProduct = products.find((product) => product.code === code);

      if (foundProduct) return foundProduct;
    }

    const url = `/product${code ? `?code=${code}` : ""}`;

    const responseProduct = await api.get(url);

    if (responseProduct.data) {
      if (!code) sessionStorage.setItem("p", encriptData(responseProduct.data));
      return responseProduct.data;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getProducts = async () => {
  try {
    const token = localStorage.getItem("adminToken");

    const responseProducts = await api.get("/product", {
      headers: {
        authorization: token,
      },
    });

    if (responseProducts.data) return responseProducts.data;

    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createProduct = async (body) => {
  try {
    const token = localStorage.getItem("adminToken");

    const createdProduct = await api.post(
      "/product",
      {
        user: process.env.REACT_APP_STORE_USER,
        ...body,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );

    if (createdProduct.data) return createdProduct.data;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const updateProduct = async (body) => {
  try {
    const { id } = body;

    if (id) {
      delete body.id;

      const token = localStorage.getItem("adminToken");

      const updatedProduct = await api.put(`/product/${id}`, body, {
        headers: {
          authorization: token,
        },
      });

      if (updatedProduct.data) return updatedProduct.data;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const deleteProduct = async (id) => {
  try {
    const token = localStorage.getItem("adminToken");

    const deletedProduct = await api.delete(`/product/${id}`, {
      headers: {
        authorization: token,
      },
    });

    if (deletedProduct.status === 204) return true;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export { getProduct, getProducts, createProduct, updateProduct, deleteProduct };
