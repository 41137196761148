import axios from "axios";

const getInfosCep = async (cep) => {
  try {

    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

    if (data.logradouro) return data;

    return {};
  } catch (err) {
    console.error(err);
    return {};
  }
};

export { getInfosCep };
