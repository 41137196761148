import styled from "styled-components";

import { Link } from "react-router-dom";
import { Modal } from 'antd';

export const StyledProductContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: transparent;
  max-width: 320px;
  width: 100%;
  margin: 30px;
  padding: 12px;
  transition: all 0.2s ease-in-out;

  :hover {
    box-shadow: 0px 0px 5px 0px ${(props) => `${props.theme.corPrimaria}AD`};

    .action {
      button {
        opacity: 1;
      }
    }
  }

  p {
    width: 100%;
    text-align: center;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 266px;
    margin-bottom: 10px;

    img {
      max-width: 100%;
      height: 100%;
    }
  }

  .product-title {
    height: 50px;
    margin: 0px;
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${(props) => props.theme.corTexto};
  }
  
  .product-price {
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.corPrimaria};
    margin: 6px 0 0 0;

    &.promotion-active {
      .price {
        font-size: 12px;
        font-weight: normal;
        color: #666;
        text-decoration: line-through;
      }

      .promotional-price {
        margin-left: 8px;
      }
    }
  }

  .action {
    display: flex;
    flex: 1;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: flex-end;
    transition: all 0.2s ease-in-out 0s;

    margin-top: 10px;
    width: 100%;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      background: #2ecc71;
      color: #fff;
      border: 0;
      padding: 6px;
      opacity: 0;
      transition: .3s ease-in-out;
      cursor: pointer;

      svg {
        width: 26px;
      }
    }
  }

  @media (max-width: 900px) {
    margin: 6px;
    width: 40vw;
    padding: 0;

    .image-container {
      height: 160px;
    }

    .product-title {
      -webkit-line-clamp: 2;
      line-clamp: 2;
      font-size: 12px;
      height: 34px;
    }

    .product-price {
      font-size: 14px;

      &.promotion-active {
        display: flex;
        flex-direction: column;

        .promotional-price {
          margin-left: 0;
        }
      }
    }

    .action {
      button {
        opacity: 1;
        padding: 4px;
      }
    }
  }

  @media (max-width: 350px) {
    width: 100%;
  }
`;

export const PromotionTag = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 6px;
  max-width: 200px;
  font-size: 10px;
  border-radius: 0 0 10px;
  background: ${(props) => props.theme.corPrimaria};
  color: ${(props) => props.theme.corFundo};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const VariantModal = styled(Modal)`
  .product-code {
    margin-top: 10px;
    font-size: 12px;
    color: "#000000";
    opacity: 0.7;
  }

  .product-price {
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.corPrimaria};
    margin: 6px 0 0 0;

    &.promotion-active {
      .price {
        font-size: 12px;
        font-weight: normal;
        color: #666;
        text-decoration: line-through;
      }

      .promotional-price {
        margin-left: 8px;
      }
    }
  }

  @media (max-width: 900px) {
    .product-price {
      font-size: 14px;
    }
  }
`;
