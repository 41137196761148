/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";

import Markdown from 'react-markdown';

import { Button, Row, Col, Divider, Input, Radio } from "antd";

import InputNumber from "../../components/Inputs/InputNumber";

import { formatPrice, openNotification } from "../../utils";

import { ProductContainer, DescriptionContainer } from "./styles";
import { StyledCarousel } from "../../styles/global";

import NotFound from "../NotFound/index";
import { getProduct } from "../../services/products";
import { getPrecoPrazo } from "../../services/frete";
import { useGlobal } from "../../contexts/Global";
import PageLoader from "../../components/PageLoader";

function ProductDetails() {
  const { globalData: configs } = useGlobal();

  const [cep, setCep] = useState("");
  const [cepLoading, setCepLoading] = useState(false);
  const [alreadySearchedCep, setAlreadySearchedCep] = useState(false);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [variant, setVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});

  const bestPromotion = product.promotions?.reduce((selectedPromotion, promotion) => {
    if (!selectedPromotion || promotion.percentage > selectedPromotion.percentage) selectedPromotion = promotion
    return selectedPromotion
  }, null)

  const { id: productCode } = useParams();

  const history = useHistory();

  const handleAddToCart = (event) => {
    event.preventDefault();

    if (!product.variants?.length > 0) {
      history.push({
        pathname: "/carrinho",
        product: { 
          ...product,
          promotionalPrice: bestPromotion ? applyPromotion(product.price) : null,
          quantity
        },
      });
      return
    }
    
    if (!variant) {
      openNotification('info', "Selecione uma variante para adicionar ao carrinho.")
      return;
    }
    
    if (!variant.available) {
      openNotification('error', "Variante selecionada está indisponível.")
      return;
    }

    history.push({
      pathname: "/carrinho",
      product: { 
        ...product,
        promotionalPrice: bestPromotion ? applyPromotion(product.price) : null,
        variant: {
          ...variant,
          promotionalPrice: bestPromotion ? applyPromotion(variant.price) : null,
        }, 
        quantity
        },
    });
  }

  const handleSelectVariant = (event) => {
    setVariant(product.variants.find(v => v.name === event.target.value))
  }

  const applyPromotion = (price) => price - (price * (bestPromotion?.percentage * 0.01)).toFixed(2)

  const getPrice = (withPromotion = false) => {
    if (!product.variants?.length > 0) return formatPrice.format(withPromotion ? applyPromotion(product.price) : product.price);

    if (variant) return formatPrice.format(withPromotion ? applyPromotion(variant.price) : variant.price)

    let highestPrice = 0
    let lowerPrice = 99999999

    product.variants.forEach(variant => {
      if (variant.price > highestPrice) highestPrice = variant.price
      if (variant.price < lowerPrice) lowerPrice = variant.price
    })

    if (lowerPrice === highestPrice || !configs.mostrarRangePreco) return formatPrice.format(withPromotion ? applyPromotion(lowerPrice) : lowerPrice)
    return `${formatPrice.format(withPromotion ? applyPromotion(lowerPrice) : lowerPrice)} - ${formatPrice.format(withPromotion ? applyPromotion(highestPrice) : highestPrice)}`
  }

  const handleCalcFrete = async () => {
    if (cep.replace(/\D/g, "").length === 8) {
      setCepLoading(true);

      setAlreadySearchedCep(false);
      setShippingOptions([]);

      const fretes = await getPrecoPrazo(cep, configs, [
        {
          ...product,
          quantity,
        },
      ]);

      setShippingOptions(fretes);
      setAlreadySearchedCep(true);

      setCepLoading(false);
    }
  };

  useEffect(() => {
    const loadProduct = async () => {
      const responseProduct = await getProduct(productCode);

      if (responseProduct) {
        setProduct(responseProduct);
        if (responseProduct.variants && !configs.mostrarRangePreco) {
          let variantWithLowerPrice = responseProduct.variants[0]

          responseProduct.variants.forEach(variant => {
            if (variant.price < variantWithLowerPrice.price) variantWithLowerPrice = variant
          })

          setVariant(variantWithLowerPrice)
        }
      }
    };

    if (productCode) loadProduct();
  }, []);

  if (!productCode) return <NotFound />;

  return (
    <>
      <ProductContainer>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <StyledCarousel>
              {product?.images?.map((image, index) => (
                <div key={`image-${index}`} className="image-container">
                  <img
                    key={`product-image-${index}`}
                    src={image}
                    alt="Imagem do produto"
                  />
                </div>
              ))}
            </StyledCarousel>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="info-container">
              <p className="product-title">{product.name}</p>
              <p className="product-code">Código do produto: {product.code}</p>
              {product.categories?.length > 0 && <p className="product-categories">Categorias: {product.categories.map((category, index) => (
                <Fragment key={category.label}>{index+1 < product.categories.length ? ' / ' : ''}{category.label}</Fragment>
              ))}</p>}
              <p className="product-short-description">
                {product.shortDescription}
              </p>

              <p className={`product-price ${bestPromotion ? 'promotion-active' : ''}`}>
                <span className="price">{getPrice()}</span>
                {bestPromotion && <span className="promotional-price">{getPrice(true)}</span>}
              </p>

              {product.variants?.length > 0 && 
                <Radio.Group buttonStyle="solid" value={variant?.name} onChange={handleSelectVariant}>
                  {product.variants.map((variant) => (
                    <Radio.Button value={variant.name} disabled={!variant.available}>{variant.name}</Radio.Button>
                  ))}
                </Radio.Group>
              }

              <span>Quantidade:</span>
              <InputNumber
                id="quantity"
                value={quantity}
                min={1}
                defaultValue={1}
                onChange={(event) => {
                  setAlreadySearchedCep(false);
                  setShippingOptions([]);
                  setQuantity(event);
                }}
              />

              <Button
                type="primary"
                className="add-cart"
                onClick={handleAddToCart}
              >
                Comprar
              </Button>

              <div className="shipping-container">
                <div>
                  <p className="product-quantity">Consultar frete:</p>
                  <Input
                    id="cep"
                    value={cep}
                    disabled={cepLoading}
                    mask="99999-999"
                    placeholder="00000-000"
                    onChange={(event) => setCep(event.target.value)}
                    onKeyPress={(event) =>
                      event.charCode === 13 ? handleCalcFrete() : null
                    }
                  />
                </div>
                <Button
                  id="btnCalcular"
                  type="primary"
                  onClick={handleCalcFrete}
                >
                  Calcular
                </Button>
              </div>
              <div className="shipping-result">
                {alreadySearchedCep && shippingOptions.length === 0 ? (
                  <p>Não foi possível calcular o frete, tente novamente.</p>
                ) : (
                  shippingOptions.map((option, index) => {
                    if (option.msgErro) return <></>;
                    return (
                      <p key={`shipping-${index}`} value={index}>
                        - {option.label}
                      </p>
                    );
                  })
                )}
              </div>
            </div>
          </Col>
        </Row>
      </ProductContainer>

      <Divider style={{ background: `${configs.corPrimaria}4D` }} />

      <DescriptionContainer>
        <p className="description-title">Descrição</p>
        <p className="description-content"><Markdown>{product.fullDescription}</Markdown></p>
        {product.height && product.width && product.depth && (
          <>
            <p className="description-title">Dimensões</p>
            <p className="description-content">Altura: {product.height}cm</p>
            <p className="description-content">Largura: {product.width}cm</p>
            <p className="description-content">
              Profundidade: {product.depth}cm
            </p>
          </>
        )}
      </DescriptionContainer>

      <PageLoader loading={cepLoading} />
    </>
  );
}

export default ProductDetails;
