import styled from "styled-components";

export const CartContainer = styled.div`
  padding: 20px;

  h1 {
    color: ${(props) => props.theme.corPrimaria};
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .empty-container {
    width: 100%;
    text-align: center;
  }

  .continue-buying {
    margin: 16px 6px;
  }

  p {
    color: ${(props) => props.theme.corTexto};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .input-label,
  p {
    color: ${(props) => props.theme.corTexto};

    b {
      color: ${(props) => props.theme.corPrimaria};
    }
  }

  #cep,
  #btnCalcular {
    max-width: 194px;
  }

  .shipping-container {
    margin: 10px;
    display: flex;
    flex-direction: column;

    .ant-radio-group {
      margin-top: 10px;
    }
  }

  .total-container {
    color: ${(props) => props.theme.corPrimaria};
    text-align: right;
    margin: 10px;
    width: 100%;

    .total-without-promotions {
      font-size: 12px;
      margin-right: 6px;
      text-decoration: line-through;
    }

    .discount-value,
    .has-discount {
      font-size: 11px;
    }
  }

  #cep {
    margin-bottom: 6px;
  }
`;

export const Subtitle = styled.h3`
  color: ${(props) => props.theme.corPrimaria};
`;
