/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { Input, Spin, Row, Col, Form, Button, ColorPicker } from "antd";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { updateGeneralConfigs } from "../../../services/session";

import { encriptData, openNotification } from "../../../utils";
import ImagePreview from "../../../components/Admin/ImagePreview";

import { useGlobal } from "../../../contexts/Global";

import * as S from "./styles";

function VisualConfigs() {
  const { globalData: generalConfigs, setGlobalData } = useGlobal();
  const [logoUrl, setLogoUrl] = useState(generalConfigs?.logo);
  const [newBannerLink, setNewBannerLink] = useState("");
  const [newBannerImage, setNewBannerImage] = useState("");
  const [homeImages, setHomeImages] = useState(
    generalConfigs.homeImages ? JSON.parse(generalConfigs.homeImages) : []
  );
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    const { corPrimaria, corTextoMenu, corFundoMenu, corFundo, corTexto } =
      values;

    const getHex = (color) => {
      if (!color) return null;
      return typeof color === "string" ? color : color.toHexString();
    };

    const isInfosComplete =
      generalConfigs.accessTokenMP &&
      generalConfigs.nome &&
      generalConfigs.cepOrigem &&
      generalConfigs.email &&
      generalConfigs.telefone;

    const newGeneralConfigs = {
      ...generalConfigs,
      ...values,
      corPrimaria: getHex(corPrimaria),
      corTextoMenu: getHex(corTextoMenu),
      corFundoMenu: getHex(corFundoMenu),
      corFundo: getHex(corFundo),
      corTexto: getHex(corTexto),
      logo: logoUrl,
      homeImages: JSON.stringify(homeImages),
      lojaEmManutencao: isInfosComplete
        ? generalConfigs.lojaEmManutencao
        : true,
    };

    const responseUpdate = await updateGeneralConfigs(newGeneralConfigs);
    setGlobalData(newGeneralConfigs);
    sessionStorage.setItem("g", encriptData(newGeneralConfigs));
    setLoading(false);

    if (responseUpdate) {
      openNotification(
        "success",
        "As configurações foram atualizadas com sucesso."
      );
      return;
    }

    openNotification(
      "error",
      "As configurações não puderam ser atualizadas, por favor tente novamente."
    );
  };

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    const updatedList = [...homeImages];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setHomeImages(updatedList);
  };

  const handleHomeImageDelete = (url) => {
    const updatedList = [...homeImages].filter((image) => image.image !== url);
    setHomeImages(updatedList);
  };

  const handleAddBanner = () => {
    setHomeImages([
      { image: newBannerImage, link: newBannerLink },
      ...homeImages,
    ]);
    setNewBannerImage("");
    setNewBannerLink("");
  };

  return (
    <div style={{ padding: "20px" }}>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Row>
            <Col sm={10} xs={16}>
              <h1>Configurações Visuais</h1>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={14}>
              <Form
                name="generalConfigs"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={generalConfigs}
                onFinish={onFinish}
              >
                <Form.Item label="URL logo" name="logo">
                  <S.InputContainer>
                    <Input
                      value={logoUrl}
                      onChange={(event) => setLogoUrl(event.target.value)}
                    />
                    <S.Logo src={logoUrl} alt="LogoPreview" />
                  </S.InputContainer>
                </Form.Item>

                <Form.Item label="Cor primária" name="corPrimaria">
                  <ColorPicker format="hex" size="large" showText />
                </Form.Item>

                <Form.Item label="Cor texto menu" name="corTextoMenu">
                  <ColorPicker format="hex" size="large" showText />
                </Form.Item>

                <Form.Item label="Cor fundo menu" name="corFundoMenu">
                  <ColorPicker format="hex" size="large" showText />
                </Form.Item>

                <Form.Item label="Cor fundo" name="corFundo">
                  <ColorPicker format="hex" size="large" showText />
                </Form.Item>

                <Form.Item label="Cor texto" name="corTexto">
                  <ColorPicker format="hex" size="large" showText />
                </Form.Item>

                <Form.Item label="Texto top banner" name="topBannerText">
                  <Input />
                </Form.Item>

                <Form.Item label="URLs Banner Home" name="homeImages">
                  <S.InputContainer>
                    <Input
                      placeholder="URL da imagem"
                      value={newBannerImage}
                      onChange={(event) =>
                        setNewBannerImage(event.target.value)
                      }
                      style={{ marginRight: "10px" }}
                    />
                    <Input
                      placeholder="Link de redirecionamento"
                      value={newBannerLink}
                      onChange={(event) => setNewBannerLink(event.target.value)}
                    />
                    <S.AdicionarBtn onClick={() => handleAddBanner()}>
                      Adicionar
                    </S.AdicionarBtn>
                  </S.InputContainer>
                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided) => (
                        <div
                          className="list-container"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {homeImages.map((item, index) => (
                            <Draggable
                              key={item.image}
                              draggableId={item.image}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="item-container"
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                >
                                  <ImagePreview
                                    position={index + 1}
                                    url={item.image}
                                    handleDelete={handleHomeImageDelete}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Salvar
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default VisualConfigs;
