import React from "react";

import { Col } from "antd";
import { Ban, CreditCard, MapPin } from "lucide-react";

import { InformationContainer } from "./styles";
import { useGlobal } from "./../../contexts/Global";
import TeamIcon from "./assets/TeamIcon";

const Information = () => {
  const { globalData: configs } = useGlobal();

  return (
    <InformationContainer>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <TeamIcon color={configs.corPrimaria} />
        <h4>Atendimento personalizado</h4>
        <p>
          Fazemos o atendimento e acompanhamento de perto com o cliente para que
          todas as dúvidas sejam sanadas e a compra seja o mais tranquila
          possível.
        </p>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <CreditCard size={100} color={configs.corPrimaria} />
        <h4>Pagamento e logística facilitados</h4>
        <p>
          O pagamento é feito através do Mercado Pago para que o cliente tenha
          toda segurança no processo.
        </p>
      </Col>

      {configs.ondeEstamos && (
        <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ marginTop: "10px" }}>
          <MapPin size={100} color={configs.corPrimaria} />
          <h4>Onde estamos</h4>
          <p>{configs.ondeEstamos}</p>
        </Col>
      )}

      {configs.termosDeCancelamento && (
        <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ marginTop: "10px" }}>
          <Ban size={100} color={configs.corPrimaria} />
          <h4>Termos de cancelamento</h4>
          <p>{configs.termosDeCancelamento}</p>
        </Col>
      )}
    </InformationContainer>
  );
};

export default Information;
