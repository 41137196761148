import { decriptData, encriptData } from "../utils";
import api from "./api";

const getActivePromotions = async () => {
  try {
    const sessionPromotions = sessionStorage.getItem("ap");
    if (sessionPromotions) {
      const promotions = decriptData(sessionPromotions);
      return promotions;
    }

    const responsePromotion = await api.get('/active-promotions');

    if (responsePromotion.data) {
      sessionStorage.setItem("ap", encriptData(responsePromotion.data));
      return responsePromotion.data;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getPromotions = async () => {
  try {
    const token = localStorage.getItem("adminToken");

    const responsePromotions = await api.get("/promotions", {
      headers: {
        authorization: token,
      },
    });

    if (responsePromotions.data) return responsePromotions.data;

    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createPromotion = async (body) => {
  try {
    const token = localStorage.getItem("adminToken");

    const createdPromotion = await api.post(
      "/promotions",
      {
        user: process.env.REACT_APP_STORE_USER,
        ...body,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );

    if (createdPromotion.data) return createdPromotion.data;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const updatePromotion = async (body) => {
  try {
    const { id } = body;

    if (id) {
      delete body.id;

      const token = localStorage.getItem("adminToken");

      const updatedPromotion = await api.put(`/promotions/${id}`, body, {
        headers: {
          authorization: token,
        },
      });

      if (updatedPromotion.data) return updatedPromotion.data;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const deletePromotion = async (id) => {
  try {
    const token = localStorage.getItem("adminToken");

    const deletedPromotion = await api.delete(`/promotions/${id}`, {
      headers: {
        authorization: token,
      },
    });

    if (deletedPromotion.status === 204) return true;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export { getActivePromotions, getPromotions, createPromotion, updatePromotion, deletePromotion };
