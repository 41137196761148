import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { Form, Button, Row, Col, Select } from "antd";

import StyledTextArea from "../../../../components/Admin/Inputs/TextArea";
import Input from "../../../../components/Admin/Inputs/Input";

import { updateOrder } from "../../../../services/orders";
import { formatPrice, openNotification } from "../../../../utils";

const { Option } = Select;

function OrderForm({ location: { order } }) {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  if (!order) {
    history.push("/admin/pedidos");
    return <></>;
  }

  const { name, prize, value } = order.shippingOption;
  let shipping = `${name} - ${formatPrice.format(value)}`

  shipping += prize ? ` - ${prize} ${prize > 1 ? "dias úteis" : "dia útil"}` : " - Combinar"

  const orderData = {
    ...order,
    date: moment(order?.date).format("DD/MM/YYYY - hh:mm:ss"),
    shipping,
    productsPrice: formatPrice.format(order?.productsPrice),
    totalPrice: formatPrice.format(order?.totalPrice),
    paymentStatus: order.payment.status,
  };

  const status = [
    {
      value: 1,
      label: "Efetuado",
    },
    {
      value: 2,
      label: "Pagamento aprovado",
    },
    {
      value: 3,
      label: "Em andamento",
    },
    {
      value: 4,
      label: "Enviado",
    },
    {
      value: 5,
      label: "Finalizado",
    },
    {
      value: 98,
      label: "Cancelado pelo cliente",
    },
    {
      value: 99,
      label: "Cancelado pela loja",
    },
  ];

  const onFinish = async (values) => {
    setLoading(true);

    const body = { ...values };

    body.productionStartDate = body.productionStartDate
      ? moment(body.productionStartDate, "DD/MM/YYYY")
      : "";
    body.sendDate = body.sendDate ? moment(body.sendDate, "DD/MM/YYYY") : "";
    body.arrivedDate = body.arrivedDate
      ? moment(body.arrivedDate, "DD/MM/YYYY")
      : "";
    body.trackingCode = body.trackingCode || "";
    body.orderId = order._id;

    delete body.productsPrice;
    delete body.observation;
    delete body.shipping;
    delete body.totalPrice;
    delete body.date;
    delete body.number;

    if (body.status !== order.status) {
      switch (body.status) {
        case 3:
          body.productionStartDate = body.productionStartDate
            ? moment(body.productionStartDate, "DD/MM/YYYY")
            : new Date().toDateString();
          break;
        case 4:
          body.sendDate = body.sendDate
            ? moment(body.sendDate, "DD/MM/YYYY")
            : new Date().toDateString();
          break;
        case 5:
          body.arrivedDate = body.arrivedDate
            ? moment(body.arrivedDate, "DD/MM/YYYY")
            : new Date().toDateString();
          break;
        default:
          console.error("Status inválido");
      }
    }

    const resp = await updateOrder(body);

    if (resp) {
      openNotification("success", "Pedido atualizado com sucesso.");
      history.push("/admin/pedidos");
      return;
    }

    openNotification(
      "error",
      "O pedido não pode ser atualizado, por favor tente novamente."
    );
    setLoading(false);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row>
        <Col sm={22} xs={18}>
          <h1>Informações do pedido {order?.number}</h1>
        </Col>

        <Col span={2}>
          <Button onClick={() => history.goBack()}>Voltar</Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={14}>
          <Form
            name="order"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={orderData}
            onFinish={onFinish}
          >
            <Form.Item label="Número" name="number">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item
              label="Status Pedido"
              name="status"
              valuePropName="defaultValue"
            >
              <Select>
                {status.map((statusItem) => (
                  <Option value={statusItem.value}>{statusItem.label}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Status Pagamento" name="paymentStatus">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label="Data do pedido" name="date">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label="Frete" name="shipping">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label="Inicio produção" name="productionStartDate">
              <Input placeHolder="__/__/____" mask="99/99/9999" />
            </Form.Item>

            <Form.Item label="Enviado" name="sendDate">
              <Input placeHolder="__/__/____" mask="99/99/9999" />
            </Form.Item>

            <Form.Item label="Código de rastreio" name="trackingCode">
              <Input />
            </Form.Item>

            <Form.Item label="Data entregue" name="arrivedDate">
              <Input placeHolder="__/__/____" mask="99/99/9999" />
            </Form.Item>

            <Form.Item label="Cliente">
              <StyledTextArea
                rows={5}
                disabled={true}
                value={`Nome: ${orderData.client?.name}\nCPF: ${orderData.client?.cpf}\nE-mail: ${orderData.client?.email}\nTelefone: ${orderData.client?.phone}`}
              />
            </Form.Item>

            <Form.Item label="Observação" name="observation">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label="Produtos">
              <table>
                <tr>
                  <th>Código</th>
                  <th>Nome</th>
                  <th>Valor</th>
                  <th>Quantidade</th>
                  <th>Valor total</th>
                </tr>
                {orderData.products?.map((product, index) => (
                  <tr key={`product-${index}`}>
                    <td>{product.id}</td>
                    <td>{product.title}</td>
                    <td>{formatPrice.format(product.unit_price)}</td>
                    <td>{product.quantity}</td>
                    <td>{formatPrice.format(product.unit_price * product.quantity)}</td>
                  </tr>
                ))}
              </table>
            </Form.Item>

            <Form.Item label="Valor Produtos" name="productsPrice">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item label="Valor Total (Produtos + Frete)" name="totalPrice">
              <Input disabled={true} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default OrderForm;
