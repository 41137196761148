/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";

const GlobalContext = createContext({});

export function useGlobal() {
  const context = useContext(GlobalContext);

  return context;
}

export function GlobalProvider({ children }) {
  const [globalData, setGlobalData] = useState(false);

  const contextValues = {
    globalData,
    setGlobalData,
  };

  return (
    <GlobalContext.Provider value={contextValues}>
      {children}
    </GlobalContext.Provider>
  );
}
