/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Statistic, Row, Col } from "antd";

import { getOrders } from "../../../services/orders";

function Home() {
  const defaultOrders = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    98: 0,
    99: 0,
  };

  const [orders, setOrders] = useState({ ...defaultOrders });
  const [loadingOrders, setLoadingOrders] = useState(true);

  const loadOrders = async () => {
    let orders = await getOrders();

    if (orders) {
      const orderCounts = orders.reduce(
        (orderCount, order) => {
          orderCount[order.status] = orderCount[order.status] + 1;

          return orderCount;
        },
        { ...defaultOrders }
      );

      setOrders(orderCounts);
    }

    setLoadingOrders(false);
  };

  useEffect(() => {
    loadOrders();
  }, []);

  return (
    <>
      <Row justify="center">
        <h1>Pedidos</h1>
      </Row>
      <Row gutter={16} justify="center" style={{ margin: 0 }}>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Efetuadas"
            loading={loadingOrders}
            value={orders[1]}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Pagamento aprovado"
            loading={loadingOrders}
            value={orders[2]}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Em andamento"
            loading={loadingOrders}
            value={orders[3]}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Enviados"
            loading={loadingOrders}
            value={orders[4]}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Finalizados"
            loading={loadingOrders}
            value={orders[5]}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Cancelados pelo Cliente"
            loading={loadingOrders}
            value={orders[98]}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Statistic
            title="Cancelados pela Loja"
            loading={loadingOrders}
            value={orders[99]}
          />
        </Col>
      </Row>
    </>
  );
}

export default Home;
