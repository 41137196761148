import styled from "styled-components";

export const PageContainer = styled.div`
  padding: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const AdicionarBtn = styled.span`
  padding: 6px 20px;
  background: ${(props) => props.theme.corPrimaria};
  border-radius: 6px;
  color: #fff;
  margin-left: 10px;
`;

export const VariantContainer = styled.div`
  border: 1px solid ${(props) => props.theme.corPrimaria}60;
  margin: 10px 0;
  padding: 10px 10px 10px 30px;
  
  .ant-checkbox-wrapper {
    margin-top: 6px;
  }

  .anticon-delete {
    color: #800000;
    position: absolute;
    right: 20px;
    margin-top: 10px;
  }

  .anticon-holder {
    position: absolute;
    left: 8px;
    margin-top: 64px;
  }
`;
