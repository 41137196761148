import api from "./api";

// const REAL_BRL = new Intl.NumberFormat("pt-BR", {
//   style: "currency",
//   currency: "BRL",
// });

// const formatarParaReal = (valor) => REAL_BRL.format(parseFloat(valor));

const getPrecoPrazo = async (cepDestino, configs, itens) => {
  const { cepOrigem, usarFreteSemValorDeclarado, combinarFreteMenosDe30 } =
    configs;
  try {
    const fretes = await api.post("/frete", {
      cepOrigem,
      cepDestino,
      combinarFreteMenosDe30,
      itens: itens.map((item) => ({
        valor: item.price,
        peso: item.weight,
        comprimento: item.boxDepth,
        altura: item.boxHeight,
        largura: item.boxWidth,
        quantidade: item.quantity,
      })),
    });

    if (fretes.data && fretes.data.length)
      return fretes.data.map((frete) => {
        let value = usarFreteSemValorDeclarado
          ? parseFloat(frete.valorSemAdicionais)
          : parseFloat(frete.valor);

        if (frete.name === "Vendedor") value = 0;

        return {
          ...frete,
          value,
        };
      });

    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

export { getPrecoPrazo };
