import { React, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useGlobal } from "../../contexts/Global";
import Menu from "../Menu";

import * as S from "./styles";
import { Link } from "react-router-dom";
import { MenuIcon, Package, Search, ShoppingCart } from "lucide-react";
import { useCart } from "./../../contexts/Cart";

function Header() {
  const { globalData: configs } = useGlobal();
  const { cartData } = useCart();

  const [menuOpen, setMenuOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const header = useRef();
  const searchInput = useRef();

  const lojaDeFeriasOuManutencao =
    configs.lojaEmManutencao || configs.lojaDeFerias;

  const handleMenuChange = () => {
    if (menuOpen) setMenuOpen(false);
    else setMenuOpen("menu");
  };

  const handleSearch = () => {
    if (searchInput.current.value) {
      const url = `/produtos?q=${searchInput.current.value.replaceAll(
        " ",
        "-"
      )}`;

      history.push(url);
    }
  };

  const handleInputChange = (e) => {
    if (e.code === "Enter") handleSearch();
  };

  useEffect(() => {
    if (searchInput && searchInput.current) searchInput.current.value = "";
  }, [location]);

  useEffect(() => {
    document.onscroll = () => {
      if (window.scrollY >= 200) {
        header.current.classList.add("scrolled");
        return;
      }

      header.current.classList.remove("scrolled");
    };
  });

  return (
    <>
      {!!configs && !configs.lojaEmManutencao && !configs.lojaDeFerias && (
        <S.HeaderContainer ref={header}>
          {configs.topBannerText && (
            <div className="top-banner-container">{configs.topBannerText}</div>
          )}
          <div className="content-container">
            <button className="menu-btn" onClick={handleMenuChange}>
              <MenuIcon size={30} color={configs.corTexto} />
            </button>
            <Link id="logo" to="/" onClick={() => setMenuOpen(false)}>
              <img alt="logo" src={configs?.logo} />
            </Link>
            {!lojaDeFeriasOuManutencao && (
              <>
                <div className="search-container">
                  <input
                    ref={searchInput}
                    type="text"
                    placeholder="Buscar produto"
                    onKeyDown={handleInputChange}
                  />
                  <Search
                    size={24}
                    color={configs.corTexto}
                    onClick={handleSearch}
                  />
                </div>
                <div className="actions">
                  <Link to="/pedidos" className="action">
                    <span>Pedidos</span>
                    <Package size={30} color={configs.corTexto} />
                  </Link>
                  <Link to="/carrinho" className="action">
                    <span>Carrinho</span>
                    <ShoppingCart size={30} color={configs.corTexto} />
                    {cartData && cartData.length > 0 && (
                      <span className="notification">
                        {cartData.length}
                      </span>
                    )}
                  </Link>
                </div>
              </>
            )}
          </div>
          <Menu
            open={menuOpen}
            setMenuOpen={setMenuOpen}
            handleMenuChange={handleMenuChange}
          />
        </S.HeaderContainer>
      )}
    </>
  );
}

export default Header;
