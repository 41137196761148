import styled from 'styled-components';

export const OverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 99999;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    & span{
    font-size: 16px;
    color: #ffffff;
    }
`;
