import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Row, Col, Table, Space, Button, Popconfirm, Input } from "antd";

import { getProducts, deleteProduct } from "../../../../services/products";
import { formatPrice, openNotification } from "../../../../utils";

import * as S from "./styles";
import { getCategories } from "../../../../services/categories";

function ProductList() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingDeletePrduct, setLoadingDeletePrduct] = useState(false);

  const [nameFilter, setNameFilter] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const history = useHistory();

  const columns = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder: sortedInfo.columnKey === "code" ? sortedInfo.order : null,
      responsive: ["md"],
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      title: "Preço",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      sortOrder: sortedInfo.columnKey === "price" ? sortedInfo.order : null,
      responsive: ["md"],
      render: (_, record) => <span>{formatPrice.format(record.price)}</span>,
    },
    {
      title: "Categorias",
      dataIndex: "categories",
      key: "categories",
      filters: categories,
      filteredValue: filteredInfo.categories || null,
      onFilter: (value, record) => record.categories.map(category => category.code).includes(value),
      responsive: ["md"],
      render: (_, record) => (
        <>
          {record.categories.map((category, index) => 
            <span style={{marginRight: '5px'}}>{category.code} - {category.label}{index+1 === record.categories.length ? '' : ', '}</span>
          )}
        </>
      ),
    },
    {
      title: "Disponível",
      dataIndex: "available",
      key: "available",
      filters: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      filteredValue: filteredInfo.available || null,
      onFilter: (value, record) => record.available === value,
      responsive: ["md"],
      render: (_, record) => <span>{record.available ? "Sim" : "Não"}</span>,
    },
    // {
    //   title: "Destaque",
    //   dataIndex: "highlight",
    //   key: "highlight",
    //   filters: [
    //     { text: "Sim", value: true },
    //     { text: "Não", value: false },
    //   ],
    //   filteredValue: filteredInfo.highlight || null,
    //   onFilter: (value, record) => record.highlight === value,
    //   responsive: ["md"],
    //   render: (_, record) => <span>{record.highlight ? "Sim" : "Não"}</span>,
    // },
    {
      title: "Operações",
      dataIndex: "operations",
      render: (_, record) =>
        products.length > 0 ? (
          <Space size="middle">
            <Button type="primary" onClick={() => handleEdit(record.index)}>
              Editar
            </Button>
            <Popconfirm
              title="Tem certeza que deseja deletar?"
              onConfirm={() => handleDelete(record.index)}
            >
              <Button disabled={loadingDeletePrduct}>Deletar</Button>
            </Popconfirm>
          </Space>
        ) : null,
    },
  ];

  const handleChange = (_, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const handleEdit = (index) => {
    history.push({
      pathname: "/admin/produtos/formulario",
      product: products[index],
    });
  };

  const handleDelete = async (index) => {
    setLoadingDeletePrduct(true);

    const deletedResponse = await deleteProduct(products[index]._id);

    if (deletedResponse) {
      let newProducts = [...products];
      newProducts.splice(index, 1);

      newProducts = newProducts.map((prod, index) => {
        prod.index = index;
        return prod;
      });

      setProducts(newProducts);
      setFilteredProducts(newProducts);
      setNameFilter("");
      openNotification("success", "Produto deletado com sucesso.");
    } else {
      openNotification(
        "error",
        "O produto não pode ser deletado, por favor tente novamente."
      );
    }

    setLoadingDeletePrduct(false);
  };

  const loadProducts = async () => {
    let products = await getProducts();

    products = products.map((prod, index) => {
      prod.index = index;
      prod.key = `product-${index}`;
      return prod;
    });

    setProducts(products);
    setFilteredProducts(products);
    setLoadingProducts(false);
  };

  const handleFilterName = () => {
    setFilteredProducts(
      products.filter((product) =>
        product.name.toLowerCase().includes(nameFilter.toLowerCase())
      )
    );
  };

  const handleInputNameChange = (e) => {
    if (e.code === "Enter") {
      handleFilterName();
      return;
    }
    setNameFilter(e.target.value);
  };

  const loadCategories = async () => {
    const responseCategories = await getCategories();
    setCategories(responseCategories.map(category => ({
      value: category.code,
      text: `${category.code} - ${category.label}`
    })));
  }

  useEffect(() => {
    loadProducts();
    loadCategories();
  }, []);

  return (
    <S.PageContainer>
      <Row align="middle" justify="space-between">
        <Row align="middle">
          <h1>Produtos</h1>
          <S.NameFilter>
            <Input
              value={nameFilter}
              onChange={handleInputNameChange}
              onKeyDown={handleInputNameChange}
              placeholder="Digite o nome do produto"
            />
            <Button type="primary" onClick={handleFilterName}>
              Filtrar
            </Button>
          </S.NameFilter>
        </Row>
        <Link to="/admin/produtos/formulario">
          <Button type="primary">Novo Produto</Button>
        </Link>
      </Row>
      <Row justify="center">
        <Col xs={24}>
          <Table
            scroll={{x: 300}}
            columns={columns}
            dataSource={filteredProducts}
            onChange={handleChange}
            loading={loadingProducts || loadingDeletePrduct}
          />
        </Col>
      </Row>
    </S.PageContainer>
  );
}

export default ProductList;
