import React from "react";

import { InputContainer, StylesInputMask } from "./styles";

function Input(props) {
  return (
    <InputContainer>
      <p className="input-label">{props.label}</p>
      <StylesInputMask {...props} />
    </InputContainer>
  );
}

export default Input;
