import { React, useState } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

import { formatPrice, openNotification } from "../../utils";
import { useGlobal } from "../../contexts/Global";
import { Radio } from "antd";

function ProductCard({ product }) {
  const { globalData } = useGlobal();

  const history = useHistory();

  const [variantModalOpen, setVariantModalOpen] = useState(false);
  
  const getCheapestVariant = () => {
    let cheapestVariant = product.variants[0]
    
    product.variants.forEach(variant => {
      if (variant.price < cheapestVariant.price) cheapestVariant = variant
    })
    
    return cheapestVariant
  }

  const [selectedVariant, setSelectedVariant] = useState(product.variants?.length > 0 ? getCheapestVariant() : null);

  const bestPromotion = product.promotions.reduce((selectedPromotion, promotion) => {
    if (!selectedPromotion || promotion.percentage > selectedPromotion.percentage) selectedPromotion = promotion
    return selectedPromotion
  }, null)
  
  const applyPromotion = (price) => price - (price * (bestPromotion?.percentage * 0.01)).toFixed(2)

  const getPrice = (withPromotion = false) => {
    if (selectedVariant) return formatPrice.format(withPromotion ? applyPromotion(selectedVariant.price) : selectedVariant.price);
    if (!product.variants?.length > 0) return formatPrice.format(withPromotion ? applyPromotion(product.price) : product.price);

    let highestPrice = 0
    let lowerPrice = 99999999

    product.variants.forEach(variant => {
      if (variant.price > highestPrice) highestPrice = variant.price
      if (variant.price < lowerPrice) lowerPrice = variant.price
    })

    if (lowerPrice === highestPrice || !globalData.mostrarRangePreco) return formatPrice.format(withPromotion ? applyPromotion(lowerPrice) : lowerPrice)
    return `${formatPrice.format(withPromotion ? applyPromotion(lowerPrice) : lowerPrice)} - ${formatPrice.format(withPromotion ? applyPromotion(highestPrice) : highestPrice)}`
  }

  const handleAddToCart = (event) => {
    event.preventDefault();

    if (!product.variants?.length > 0) {
      history.push({
        pathname: "/carrinho",
        product: { 
          ...product,
          promotionalPrice: bestPromotion ? applyPromotion(product.price) : null,
          quantity: 1
        },
      });
      return
    }
    
    if (!selectedVariant) {
      openNotification('info', "Selecione uma variante para adicionar ao carrinho.")
      return;
    }
    
    if (!selectedVariant.available) {
      openNotification('error', "Variante selecionada está indisponível.")
      return;
    }

    history.push({
      pathname: "/carrinho",
      product: { 
        ...product,
        promotionalPrice: bestPromotion ? applyPromotion(product.price) : null,
        variant: {
          ...selectedVariant,
          promotionalPrice: bestPromotion ? applyPromotion(selectedVariant.price) : null,
        }, 
        quantity: 1
        },
    });
  }

  return (
    <>
      <S.StyledProductContainer
        to={{
          pathname: `/detalhes-produto/${product.code}`,
          product: product,
        }}
      >
        <div className="image-container">
          {bestPromotion && <S.PromotionTag>{bestPromotion.customLabel ?? `-${bestPromotion.percentage}%`}</S.PromotionTag>}
          <img src={product.images[0]} alt={product.images[0]} loading="lazy" />
        </div>
        <p className="product-title">{product.name}</p>
        <p className={`product-price ${bestPromotion ? 'promotion-active' : ''}`}>
          <span className="price">{getPrice()}</span>
          {bestPromotion && <span className="promotional-price">{getPrice(true)}</span>}
        </p>
        <div className="action">
          <button onClick={product.variants?.length > 0 ? (e) => {
            e.preventDefault();
            setVariantModalOpen(true)
           } : handleAddToCart}>Comprar</button>
        </div>
      </S.StyledProductContainer>
      
      {product.variants?.length > 0 && 
        <S.VariantModal 
          title={product.name}
          open={variantModalOpen} 
          onOk={handleAddToCart} 
          onCancel={() => {
            setVariantModalOpen(false)
            setSelectedVariant(getCheapestVariant())
          }}
          okText="Adicionar ao carrinho"
          cancelText="Cancelar"
          closeIcon={false}
          okButtonProps={{ style: {backgroundColor: "#2ecc71", color: "#FFF", boxShadow: "none"} }}
        >
          <p className="product-code">Código do produto: {product.code}</p>
          <p className={`product-price ${bestPromotion ? 'promotion-active' : ''}`}>
            <span className="price">{getPrice()}</span>
            {bestPromotion && <span className="promotional-price">{getPrice(true)}</span>}
          </p>
          <Radio.Group 
            buttonStyle="solid" 
            value={selectedVariant?.name} 
            style={{marginTop: "6px"}}
            onChange={(event) => setSelectedVariant(product.variants.find(v => v.name === event.target.value))}
          >
            {product.variants.map((variant) => (
              <Radio.Button value={variant.name} disabled={!variant.available}>{variant.name}</Radio.Button>
            ))}
          </Radio.Group>
        </S.VariantModal>
      }
    </>
  );
}

export default ProductCard;
