import api from "./api";

const createSession = async (user, password) => {
  try {
    const responseSession = await api.post("/session", {
      user,
      password,
    });

    if (responseSession.data) return responseSession.data.token;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const verifySession = async (token) => {
  try {
    const responseSession = await api.post("/session/verify", {
      token,
    });

    if (responseSession?.data?.valid) return true;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getGeneralConfigs = async () => {
  try {
    const responseGeneralConfigs = await api.get(
      `/getGeneralConfigs?admin=true`
    );

    if (responseGeneralConfigs.data)
      return JSON.parse(responseGeneralConfigs.data);

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const updateGeneralConfigs = async (newGeneralConfigs) => {
  try {
    const token = localStorage.getItem("adminToken");

    const responseGeneralConfigs = await api.put(
      "/updateGeneralConfigs",
      newGeneralConfigs,
      {
        headers: {
          authorization: token,
        },
      }
    );

    if (responseGeneralConfigs.data) return responseGeneralConfigs.data;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export {
  createSession,
  verifySession,
  getGeneralConfigs,
  updateGeneralConfigs,
};
