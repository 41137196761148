import React from "react";

import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";

const Vacation = () => (
  <Result
    icon={<SmileOutlined />}
    title="Nossa loja está de férias no momento, voltamos em breve para lhe atender cada vez melhor!"
  />
);

export default Vacation;
