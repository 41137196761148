import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import moment from "moment";
import { Row, Col, Table, Space, Button, Popconfirm, Input } from "antd";

import { getPromotions, deletePromotion } from "../../../../services/promotions";
import { openNotification } from "../../../../utils";

import * as S from "./styles";

function PromotionList() {
  const [promotions, setPromotions] = useState([]);
  const [filteredPromotions, setFilteredPromotions] = useState([]);
  const [loadingPromotions, setLoadingPromotions] = useState(true);
  const [loadingDeletePromotion, setLoadingDeletePromotion] = useState(false);

  const [nameFilter, setNameFilter] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});

  const history = useHistory();

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    },
    {
      title: "Ativa",
      dataIndex: "active",
      key: "active",
      render: (_, record) => record.active ? 'Sim' : 'Não',
      sorter: (a, b) => a.active - b.active,
      sortOrder: sortedInfo.columnKey === "active" ? sortedInfo.order : null,
    },
    {
      title: "Data de início",
      dataIndex: "startDate",
      key: "startDate",
      render: (_, record) => moment(record.startDate).format('DD/MM/YYYY'),
      sorter: (a, b) => a.startDate - b.startDate,
      sortOrder: sortedInfo.columnKey === "startDate" ? sortedInfo.order : null,
    },
    {
      title: "Data de fim",
      dataIndex: "endDate",
      key: "endDate",
      render: (_, record) => moment(record.endDate).format('DD/MM/YYYY'),
      sorter: (a, b) => a.endDate - b.endDate,
      sortOrder: sortedInfo.columnKey === "endDate" ? sortedInfo.order : null,
    },
    {
      title: "Operações",
      dataIndex: "operations",
      render: (_, record) =>
        promotions.length > 0 ? (
          <Space size="middle">
            <Button type="primary" onClick={() => handleEdit(record.index)}>
              Editar
            </Button>
            <Popconfirm
              title="Tem certeza que deseja deletar?"
              onConfirm={() => handleDelete(record.index)}
            >
              <Button disabled={loadingDeletePromotion}>Deletar</Button>
            </Popconfirm>
          </Space>
        ) : null,
    },
  ];

  const handleChange = (paginagion, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleEdit = (index) => {
    history.push({
      pathname: "/admin/promocoes/formulario",
      promotion: promotions[index],
    });
  };

  const handleDelete = async (index) => {
    setLoadingDeletePromotion(true);

    const deletedResponse = await deletePromotion(promotions[index]._id);

    if (deletedResponse) {
      let newPromotions = [...promotions];
      newPromotions.splice(index, 1);

      newPromotions = newPromotions.map((promotion, index) => {
        promotion.index = index;
        return promotion;
      });

      setPromotions(newPromotions);
      setFilteredPromotions(newPromotions);
      setNameFilter("");
      openNotification("success", "Promoção deletada com sucesso.");
    } else {
      openNotification(
        "error",
        "A promoção não pode ser deletada, por favor tente novamente."
      );
    }

    setLoadingDeletePromotion(false);
  };

  const loadPromotions = async () => {
    let promotions = await getPromotions();

    promotions = promotions.map((promotion, index) => {
      promotion.index = index;
      promotion.key = `promotion-${index}`;
      return promotion;
    });

    setPromotions(promotions);
    setFilteredPromotions(promotions);
    setLoadingPromotions(false);
  };

  const handleFilterName = () => {
    setFilteredPromotions(
      promotions.filter((promotion) =>
        promotion.name.toLowerCase().includes(nameFilter.toLowerCase())
      )
    );
  };

  const handleInputNameChange = (e) => {
    if (e.code === "Enter") {
      handleFilterName();
      return;
    }
    setNameFilter(e.target.value);
  };

  useEffect(() => {
    loadPromotions();
  }, []);

  return (
    <S.PageContainer>
      <Row align="middle" justify="space-between">
        <Row align="middle">
          <h1>Promoções</h1>
          <S.NameFilter>
            <Input
              value={nameFilter}
              onChange={handleInputNameChange}
              onKeyDown={handleInputNameChange}
              placeholder="Digite o nome da promoção"
            />
            <Button type="primary" onClick={handleFilterName}>
              Filtrar
            </Button>
          </S.NameFilter>
        </Row>
        <Link to="/admin/promocoes/formulario">
          <Button type="primary">Nova Promoção</Button>
        </Link>
      </Row>
      <Row justify="center">
        <Col xs={24}>
          <Table
            scroll={{x: 300}}
            columns={columns}
            dataSource={filteredPromotions}
            onChange={handleChange}
            loading={loadingPromotions || deletePromotion}
          />
        </Col>
      </Row>
    </S.PageContainer>
  );
}

export default PromotionList;
