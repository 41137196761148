import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Paragraph = styled.p`
  color: rgba(0, 0, 0, 0.85);
  width: 400px;
  text-align: center;
`;
