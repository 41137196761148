import { React } from "react";

import { StyledContainer } from "./styles";
import { DeleteFilled, HolderOutlined } from "@ant-design/icons";

function ImagePreview({ position, url, handleDelete, imageWidth = "100%" }) {
  return (
    <StyledContainer>
      <HolderOutlined />
      {position && <span>{position}º</span>}
      <a href={url} target="_blank" rel="noreferrer">
        <img style={{ width: imageWidth }} src={url} alt="ImagePreview" />
      </a>
      <DeleteFilled onClick={() => handleDelete(url)} />
    </StyledContainer>
  );
}

export default ImagePreview;
