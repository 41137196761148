import React, { useState } from "react";
import moment from "moment";

import { Button, Steps, Spin, Result } from "antd";

import Input from "../../components/Inputs/Input";

import { OrderStatusContainer } from "./styles";
import { getOrder } from "../../services/orders";

const { Step } = Steps;

function OrderStatus() {
  const [cpf, setCpf] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [order, setOrder] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [alreadySearchedOrder, setAlreadySearchedOrder] = useState(false);

  const handleSearchOrder = async () => {
    if (cpf && orderNumber) {
      setLoadingOrder(true);

      const searchedOrder = await getOrder({
        cpf: cpf.replaceAll(/\D/g, ""),
        orderNumber,
      });

      setOrder(searchedOrder);
      setAlreadySearchedOrder(true);

      setLoadingOrder(false);
    }
  };

  return (
    <OrderStatusContainer>
      <h1>Consultar pedidos</h1>

      <div className="input-container">
        <Input
          id="cpf"
          mask="999.999.999-99"
          placeholder="CPF"
          value={cpf}
          onChange={(event) => setCpf(event.target.value)}
        />
        <Input
          id="orderNumber"
          placeholder="Número do pedido"
          value={orderNumber}
          onChange={(event) => setOrderNumber(event.target.value)}
          onKeyPress={(event) =>
            event.charCode === 13 ? handleSearchOrder() : null
          }
        />
        <Button type="primary" onClick={handleSearchOrder}>
          Consultar
        </Button>
      </div>

      {loadingOrder && (
        <div className="loading-container">
          <Spin />
        </div>
      )}
      {order && !loadingOrder && (
        <div className="steps-container">
          {order.status === 99 || order?.status === 98 ? (
            <Result
              status="error"
              title="Pedido cancelado"
              subTitle="Seu pedido foi cancelado, caso não seja o que esperava por favor entrar em contato com a loja."
            ></Result>
          ) : (
            <Steps current={order?.status} responsive>
              <Step
                title="Pedido realizado"
                description={moment(order?.date).format("DD/MM/YYYY")}
              />
              <Step
                title={
                  order?.status === 1
                    ? "Aguardando pagamento"
                    : "Pagamento realizado"
                }
              />
              <Step
                title="Em produção/preparação"
                description={
                  order?.productionStartDate
                    ? moment(order?.productionStartDate).format("DD/MM/YYYY")
                    : ""
                }
              />
              <Step
                title="Enviado"
                subTitle={
                  <a
                    target="blank"
                    href={`https://www.linkcorreios.com.br/${order?.trackingCode}`}
                  >
                    {order?.trackingCode}
                  </a>
                }
                description={
                  order?.sendDate
                    ? moment(order?.sendDate).format("DD/MM/YYYY")
                    : ""
                }
              />
              <Step
                title="Recebido"
                description={
                  order?.arrivedDate
                    ? moment(order?.arrivedDate).format("DD/MM/YYYY")
                    : ""
                }
              />
            </Steps>
          )}
        </div>
      )}

      {!order?.status && alreadySearchedOrder && !loadingOrder && (
        <Result
          status="error"
          title="Pedido não encontrado"
          subTitle="Não conseguimos localizar seu pedido, por favor verifique os dados
digitados e tente novamente."
        ></Result>
      )}
    </OrderStatusContainer>
  );
}

export default OrderStatus;
