import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 300px;
  margin: 6px 0;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid ${(props) => props.theme.corPrimaria};

  span,
  a {
    margin-right: 10px;
  }

  .anticon-delete {
    color: #800000;
    font-size: 16px;
    margin-right: 0;
  }
`;
