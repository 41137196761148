import React from "react";

import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

function NotFound() {
  const history = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, a página que você visitou não existe."
      extra={
        <Button type="primary" onClick={() => history.push("/")}>
          Voltar ao início
        </Button>
      }
    />
  );
}

export default NotFound;
