/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form, Button, Checkbox, Row, Col, Select, Space, Tag } from "antd";

import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";

import Input from "../../../../components/Admin/Inputs/Input";
import InputNumber from "../../../../components/Admin/Inputs/InputNumber";
import StyledTextArea from "../../../../components/Admin/Inputs/TextArea/index";
import ImagePreview from "../../../../components/Admin/ImagePreview";

import { openNotification } from "../../../../utils";
import { createProduct, updateProduct } from "../../../../services/products";

import * as S from "./styles";
import { getCategories } from "../../../../services/categories";
import { DeleteFilled, HolderOutlined } from "@ant-design/icons";

function ProductForm({ location: { product } }) {
  const [productImages, setProductImages] = useState(
    product && product.images ? [...product.images] : []
  );
  const [productVariants, setProductVariants] = useState(
    product && product.variants ? [...product.variants] : null
  )
  const [newProductImage, setNewProductImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const history = useHistory();

  const onFinish = async (values) => {
    if (productVariants?.length && productVariants?.some(variant => !variant.price)) {
      openNotification(
        "error",
        `O produto não pode ser ${
          product ? "atualizado" : "criado"
        }, por favor tente novamente.`
      );
      return;
    }

    setLoading(true);

    const body = { ...values };

    body.shortDescription = body.shortDescription || "";
    body.fullDescription = body.fullDescription || "";
    body.available = body.available || false;
    body.highlight = body.highlight || false;
    body.releaseDate = product?.releaseDate || new Date().toISOString();

    body.images = [...productImages];
    if (productVariants) body.variants = [...productVariants];

    let resp;
    if (product) {
      body.id = product._id;

      resp = await updateProduct(body);
    } else {
      resp = await createProduct(body);
    }

    if (resp) {
      openNotification(
        "success",
        `Produto ${product ? "atualizado" : "criado"} com sucesso.`
      );
      history.push("/admin/produtos");
      return;
    }

    openNotification(
      "error",
      `O produto não pode ser ${
        product ? "atualizado" : "criado"
      }, por favor tente novamente.`
    );
    setLoading(false);
  };

  const handleImageDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    const updatedList = [...productImages];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setProductImages(updatedList);
  };

  const handleVariantDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    const updatedList = [...productVariants];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setProductVariants(updatedList);
  };
  
  const handleProductImageDelete = (url) => {
    const updatedList = [...productImages].filter((image) => image !== url);
    setProductImages(updatedList);
  };

  const handleAddImage = () => {
    setProductImages([newProductImage, ...productImages]);
    setNewProductImage("");
  };

  const handleVariantChange = (event, variantIndex, prop) => {
    const newVariants = [...productVariants];
    
    switch (prop) {
      case 'available':
        newVariants[variantIndex][prop] = event.target.checked;
        break;
      case 'price':
        newVariants[variantIndex][prop] = event;
        break;
      case 'name':
        newVariants[variantIndex][prop] = event.target.value;
        break;
      default: 
        console.error("Property invalid!")
    }

    setProductVariants(newVariants);
  }

  const handleAddVariant = () => {
    const newVariant = {
      name: '',
      price: 0,
      available: true
    }
    setProductVariants(productVariants ? [newVariant, ...productVariants] : [newVariant])
  }

  const loadCategories = async () => {
    const responseCategories = await getCategories();
    setCategories(responseCategories.map(category => ({
      value: category.code,
      label: category.label
    })));
  }

  useEffect(() => {
    loadCategories();

    if (product) {
      product.categories = product.categories.map(category => category?.code)
    }
  }, [])
  
  return (
    <S.PageContainer>
      <Row>
        <Col span={22}>
          <h1>{!product ? "Novo" : "Editar"} produto</h1>
        </Col>

        <Col span={2}>
          <Button onClick={() => history.push("/admin/produtos")}>
            Voltar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={14}>
          <Form
            name="product"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={ product ? { ...product, categories: product.categories?.map(category => category?.code)} : {}}
            onFinish={onFinish}
          >
            <Form.Item
              label="Código"
              name="code"
              rules={[{ required: true, message: "Código é obrigatório" }]}
            >
              <Input disabled={product?.code} />
            </Form.Item>

            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: "Nome é obrigatório" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Descrição curta" name="shortDescription">
              <Input />
            </Form.Item>

            <Form.Item label="Descrição completa" name="fullDescription">
              <StyledTextArea rows={5} />
            </Form.Item>

            <Form.Item
              label="Preço"
              name="price"
              rules={[{ required: true, message: "Preço é obrigatório" }]}
            >
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item
              label="Peso (Kg)"
              name="weight"
              rules={[{ required: true, message: "Peso é obrigatório" }]}
            >
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item label="Altura (cm)" name="height">
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item label="Largura (cm)" name="width">
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item label="Profundidade (cm)" name="depth">
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item
              label="Altura embalado (cm)"
              name="boxHeight"
              rules={[
                { required: true, message: "Altura embalado é obrigatório" },
              ]}
            >
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item
              label="Largura embalado (cm)"
              name="boxWidth"
              rules={[
                { required: true, message: "Largura embalado é obrigatório" },
              ]}
            >
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item
              label="Profundidade embalado (cm)"
              name="boxDepth"
              rules={[
                {
                  required: true,
                  message: "Profundidade embalado é obrigatório",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} step="0.01" min={0.01} />
            </Form.Item>

            <Form.Item label="URLs Imagens" name="images">
              <>
                <S.InputContainer>
                  <Input
                    value={newProductImage}
                    onChange={(event) => setNewProductImage(event.target.value)}
                  />
                  <S.AdicionarBtn onClick={() => handleAddImage()}>
                    Adicionar
                  </S.AdicionarBtn>
                </S.InputContainer>
                <DragDropContext onDragEnd={handleImageDrop}>
                  <Droppable droppableId="list-images">
                    {(provided) => (
                      <div
                        className="list-images"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {productImages?.map((item, index) => (
                          <Draggable key={item} draggableId={item} index={index}>
                            {(provided) => (
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <ImagePreview
                                  imageWidth="100px"
                                  position={index + 1}
                                  url={item}
                                  handleDelete={handleProductImageDelete}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            </Form.Item>

            <Form.Item
              label="Categorias"
              name="categories"
              rules={[{ required: true, message: "Pelo menos uma categoria é obrigatório" }]}
            >
              <Select 
                showSearch 
                mode="multiple" 
                tagRender={({value, label, onClose}) => (
                  <Tag closable onClose={onClose}>
                    {value} - {label}
                  </Tag>
                )}
                options={categories}
                optionRender={(option) => (
                  <Space>
                    <span role="img" aria-label={option.data.label}>
                      {option.data.value} - {option.data.label}
                    </span>
                  </Space>
                )}
              />
            </Form.Item>

            <Form.Item
              label="Variantes"
              name="variants"
            >
              <>
                <Button type="primary" onClick={handleAddVariant}>Nova variante</Button>
                <DragDropContext onDragEnd={handleVariantDrop}>
                  <Droppable droppableId="list-variants">
                    {(provided) => (
                      <div
                        className="list-variants"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {productVariants?.map((variant, index) => (
                          <Draggable key={`variant${index}`} draggableId={`variant${index}`} index={index}>
                            {(provided) => (
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <S.VariantContainer className="variant">
                                  <HolderOutlined />
                                  <Input 
                                    label="Nome"
                                    value={variant.name}
                                    onChange={(event) => handleVariantChange(event, index, 'name')} 
                                  />
                                  <InputNumber 
                                    label="Preço" 
                                    style={{ width: "100%" }} 
                                    step="0.01" min={0.01}
                                    value={variant.price}
                                    onChange={(event) => handleVariantChange(event, index, 'price')} 
                                  />
                                  <Checkbox 
                                    checked={variant.available}
                                    onChange={(event) => handleVariantChange(event, index, 'available')}
                                  >
                                    Disponível
                                  </Checkbox>
                                  <DeleteFilled onClick={() => {
                                    const newProductVariants = [...productVariants]
                                    newProductVariants.splice(index, 1)
                                    setProductVariants(newProductVariants)
                                  }} />
                                </S.VariantContainer>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            </Form.Item>

            <Form.Item
              name="available"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Disponível</Checkbox>
            </Form.Item>

            <Form.Item
              name="highlight"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Destaque</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {!product ? "Criar" : "Salvar"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </S.PageContainer>
  );
}

export default ProductForm;
