import React from "react";
import { useHistory } from "react-router-dom";

import { Result, Button } from "antd";

const OrderFailed = () => {
  const history = useHistory();

  return (
    <Result
      status="error"
      title="Pedido recusado"
      subTitle="Algo deu errado ao efetuar o pedido, por favor revise as informações e tente novamente."
      extra={
        <Button type="primary" onClick={() => history.push("/")}>
          Voltar ao início
        </Button>
      }
    />
  );
};

export default OrderFailed;
