import { notification } from "antd";

import { getGeneralConfigs } from "../services/session";

export const formatPrice = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export const getConfigs = async () => {
  let jsonConfigs;

  const sessionGeneralConfigs = sessionStorage.getItem("g");

  if (sessionGeneralConfigs) jsonConfigs = decriptData(sessionGeneralConfigs);

  if (!jsonConfigs || jsonConfigs.lojaEmManutencao) {
    const configsResponse = await getGeneralConfigs();

    if (!configsResponse) {
      return false;
    }

    sessionStorage.setItem("g", encriptData(configsResponse));

    jsonConfigs = configsResponse;
  }

  return jsonConfigs;
};

export const openNotification = (type, description, duration = 2.5) => {
  let message;

  switch(type) {
    case "success":
      message = "Sucesso";
      break;
    case "error":
      message = "Erro";
      break;
    case "warning": 
    case "info":
      message = "Aviso"
      break;
    default:
      message = 'Notificação'
  }
  notification[type]({ message, description, duration });
};

export const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const encriptData = (data) => window.btoa(JSON.stringify(data));
export const decriptData = (data) => JSON.parse(window.atob(data));
