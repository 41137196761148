import React, { useState, useEffect } from "react";

import CarouselHome from "../../components/CarouselHome";
import ProductShelf from "./../../components/ProductShelf/index";

import { getProduct } from "../../services/products";
import { useGlobal } from "./../../contexts/Global";

function Home() {
  const { globalData: configs } = useGlobal();

  const [products, setProducts] = useState([]);

  const loadProducts = async () => {
    const productsResponse = (await getProduct()) || [];

    let productsHighlight = productsResponse.filter(
      (product) => product.highlight
    );

    if (!productsHighlight.length) productsHighlight = productsResponse.slice(0, 10)

    setProducts(productsHighlight);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <>
      <CarouselHome images={configs?.homeImages} />
      <ProductShelf title="Destaques" products={products} />
    </>
  );
}

export default Home;
