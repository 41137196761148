/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Input,
  InputNumber,
  Spin,
  Row,
  Col,
  Form,
  Button,
  Checkbox,
} from "antd";

import TextArea from "antd/lib/input/TextArea";

import { updateGeneralConfigs } from "../../../services/session";

import { encriptData, openNotification } from "../../../utils";

import { useGlobal } from "../../../contexts/Global";

function GeneralConfigs() {
  const { globalData: generalConfigs, setGlobalData } = useGlobal();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {

    if (values.valorMinimoFreteVendedor && values.valorMaximoFreteVendedor) {
      openNotification(
        "error",
        "Só é possível valor mínimo OU valor máximo para frete com vendedor."
      );
      return;
    }

    setLoading(true);

    const isInfosComplete =
      values.accessTokenMP &&
      values.cepOrigem &&
      values.email &&
      values.telefone;

    const newGeneralConfigs = {
      ...generalConfigs,
      ...values,
      lojaEmManutencao: isInfosComplete ? values.lojaEmManutencao : true,
    };

    const responseUpdate = await updateGeneralConfigs(newGeneralConfigs);
    setGlobalData(newGeneralConfigs);
    sessionStorage.setItem("g", encriptData(newGeneralConfigs));
    setLoading(false);

    if (responseUpdate) {
      openNotification(
        "success",
        "As configurações foram atualizadas com sucesso."
      );
      return;
    }

    openNotification(
      "error",
      "As configurações não puderam ser atualizadas, por favor tente novamente."
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [generalConfigs]);

  return (
    <div style={{ padding: "20px" }}>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Row>
            <Col sm={10} xs={16}>
              <h1>Configurações Gerais</h1>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={14}>
              <Form
                name="generalConfigs"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={generalConfigs}
                onFinish={onFinish}
              >
                <Form.Item label="CEP Origem" name="cepOrigem">
                  <Input />
                </Form.Item>

                <Form.Item label="Quem somos?" name="quemSomos">
                  <TextArea />
                </Form.Item>

                <Form.Item label="Onde estamos" name="ondeEstamos">
                  <TextArea />
                </Form.Item>

                <Form.Item
                  label="Descrição curta"
                  name="shortFooterDescription"
                >
                  <TextArea />
                </Form.Item>

                <Form.Item label="Email" name="email">
                  <Input />
                </Form.Item>

                <Form.Item label="Telefone" name="telefone">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Instagram"
                  name="instagram"
                  tooltip={"Apenas o @"}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Termos de cancelamento"
                  name="termosDeCancelamento"
                >
                  <TextArea />
                </Form.Item>

                <Form.Item
                  name="lojaEmManutencao"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>Loja em manutenção</Checkbox>
                </Form.Item>

                <Form.Item
                  name="lojaDeFerias"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>Loja em férias</Checkbox>
                </Form.Item>

                <Form.Item
                  name="mostrarRangePreco"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>Mostrar preço de variante mais caro e mais barato</Checkbox>
                </Form.Item>

                {/* <Form.Item
                  name="lojaSemFrete"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>
                    Ocultar opções para calculo de frete
                  </Checkbox>
                </Form.Item> */}

                <Form.Item
                  name="combinarFreteVendedor"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                >
                  <Checkbox>
                    Mostrar opção de frete com o vendedor
                  </Checkbox>
                </Form.Item>
                
                <Form.Item
                  label="Valor mínimo para frete com vendedor"
                  name="valorMinimoFreteVendedor"
                >
                  <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                  label="Valor máximo para frete com vendedor"
                  name="valorMaximoFreteVendedor"
                >
                  <InputNumber min={0} />
                </Form.Item>

                <Form.Item label="Url da loja" name="urlDaLoja">
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Tempo de produção dos produtos"
                  name="tempoDeProducao"
                >
                  <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                  label="Access Token Mercado Pago"
                  name="accessTokenMP"
                >
                  <Input />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Salvar
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default GeneralConfigs;
