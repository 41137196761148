import api from "./api";

const createPreference = async (body) => {
  try {
    const url = `/createPreference`;

    const origin = window.location.origin;

    const responsePreference = await api.post(url, {
      ...body,
      user: process.env.REACT_APP_STORE_USER,
      back_urls: {
        success: `${origin}/pedido-efetuado`,
        pending: `${origin}/pedido-efetuado`,
        failure: `${origin}/pedido-falha`,
      },
    });

    if (responsePreference.data && responsePreference.data.id)
      return responsePreference.data.id;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export { createPreference };
