import React from 'react';

import { InputNumber } from 'antd';
import { InputContainer } from './styles';

function StyledInputNumber(props) {

    return (
        <InputContainer>
            <p className="input-label">{props.label}</p>
            <InputNumber id="quantity" value={props.quantity} min={1} defaultValue={1} onChange={props.setQuantity} {...props} />
        </InputContainer>
    );
}

export default StyledInputNumber;
