import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 6px;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid ${(props) => props.theme.corPrimaria};

  .name-container {
    display: flex;
    align-items: center;
    min-width: 300px;

    img {
      width: 40px;
      margin-right: 6px;
    }
  }

  .product-title {
    margin: 0px;
    font-size: 16px;
    color: ${(props) => props.theme.corPrimaria};
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
  }

  .product-variant {
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .product-price {
    margin: 0px;
    color: ${(props) => props.theme.corTexto};
    width: 120px;
    text-align: center;

    &.promotion-active {
      .price {
        font-size: 10px;
        text-decoration: line-through;
      }

      .promotional-price {
        margin-left: 6px;
      }
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 620px) {
    min-width: 200px;

    .name-container {
      margin-bottom: 10px;
    }
  }
`;
