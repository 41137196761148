/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Modal, Button, Radio, Space } from "antd";

import * as S from "./styles";

import Input from "../../components/Inputs/Input";
import TextArea from "../../components/Inputs/TextArea";
import PageLoader from "../../components/PageLoader";
import CartProduct from "../../components/CartProduct";

import { formatPrice } from "./../../utils";

import { useCart } from "./../../contexts/Cart";
import { Link } from "react-router-dom";

import { getPrecoPrazo } from "../../services/frete";
import { createPreference } from "../../services/preferences";
import { getInfosCep } from "../../services/buscaCep";
import { useGlobal } from "./../../contexts/Global";
import { createOrder } from "../../services/orders";

function Cart() {
  const { globalData: configs } = useGlobal();

  const history = useHistory();
  const location = useLocation();

  const {
    cartData,
    addProduct,
    totalProductsPrice,
    totalProductsPriceWithoutPromotions,
    shipping,
    setShipping,
    clearShipping,
    orderPrice,
  } = useCart();

  const [cep, setCep] = useState("");
  const [observacao, setObservacao] = useState("");
  const [cepLoading, setCepLoading] = useState(false);
  const [cliente, setCliente] = useState({});
  const [endereco, setEndereco] = useState({});
  const [alreadySearchedCep, setAlreadySearchedCep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [onPayment, setOnPayment] = useState(false);

  const hasDiscount =
    totalProductsPrice !== totalProductsPriceWithoutPromotions;

  const handleCalcFrete = async () => {
    if (cep.replace(/\D/g, "").length === 8) {
      setCepLoading(true);

      setAlreadySearchedCep(false);
      clearShipping();

      const fretes = await getPrecoPrazo(cep, configs, cartData);

      setShipping({
        ...shipping,
        options: fretes,
      });
      setAlreadySearchedCep(true);

      setCepLoading(false);
    }
  };

  const handleChangeShipping = (event) => {
    setShipping({
      ...shipping,
      selected: event.target.value,
      price: shipping.options[event.target.value].value,
    });
  };

  const handleCancel = () => {
    setModalVisible(false);
    setLoading(false);
    setOnPayment(false);
    if (window.paymentBrickController) window.paymentBrickController.unmount();
  };

  const handleOpenModal = async () => {
    setModalVisible(true);

    const endereco = await getInfosCep(cep);

    setEndereco(endereco);
  };

  const handleChangeEndereco = (event) => {
    setEndereco({
      ...endereco,
      [event.target.id]: event.target.value,
    });
  };

  const handleChangeCliente = (event) => {
    setCliente({
      ...cliente,
      [event.target.id]: event.target.value,
    });
  };

  const handleContinue = async () => {
    if (cartData.length > 0 && formValid) {
      setLoading(true);
      setOnPayment(true);

      const items = cartData.map((item) => {
        let unit_price = item.variant ? item.variant.promotionalPrice ?? item.variant.price : item.promotionalPrice ?? item.price;
        
        return {
          id: item.code,
          title: item.variant
            ? `${item.name} - ${item.variant.name}`
            : item.name,
          categories: item.categories,
          description: item.shortDescription,
          picture_url: item.images[0],
          currency_id: "BRL",
          quantity: item.quantity,
          unit_price: parseFloat(unit_price),
          variant: item.variant,
        };
      });

      items.push({
        title: `Frete (${shipping.label ?? "Combinar com vendedor"})`,
        unit_price: shipping.price,
        currency_id: "BRL",
        quantity: 1,
      });

      const order = {
        payer: {
          first_name: cliente.nome,
          last_name: cliente.sobrenome,
          email: cliente.email,
          phone: {
            number: cliente.telefone,
          },
          address: {
            metadata: {
              complement: endereco.complemento,
            },
            street_name: endereco.logradouro,
            street_number: endereco.numero,
            zip_code: cep,
            city: {
              name: endereco.localidade,
            },
            state: {
              name: endereco.uf,
            },
            neighborhood: {
              name: endereco.bairro,
            },
          },
          identification: {
            number: cliente.cpf,
            type: "CPF",
          },
        },
        items,
        observation: observacao,
        totalProductsPrice,
        shipping,
        orderPrice,
      };

      const preferenceId = await createPreference(order);

      const mp = new window.MercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY);
      const bricksBuilder = mp.bricks();

      const renderPaymentBrick = async (bricksBuilder) => {
        const settings = {
          initialization: {
            amount: orderPrice,
            preferenceId,
            payer: {
              firstName: cliente.nome,
              lastName: cliente.sobrenome,
              identification: {
                number: cliente.cpf,
                type: "CPF",
              },
              email: cliente.email,
              address: {
                zipCode: endereco.cep,
                federalUnit: endereco.uf,
                city: endereco.localidade,
                neighborhood: endereco.bairro,
                streetName: endereco.logradouro,
                streetNumber: endereco.numero,
                complement: endereco.complemento,
              },
            },
          },
          customization: {
            visual: {
              style: {
                theme: "default",
              },
            },
            paymentMethods: {
              creditCard: "all",
              debitCard: "all",
              ticket: "all",
              bankTransfer: "all",
              atm: "all",
              onboarding_credits: "all",
              wallet_purchase: "all",
            },
          },
          callbacks: {
            onReady: () => {},
            onSubmit: async ({ selectedPaymentMethod, formData }) => {
              const newOrder = await createOrder({
                order,
                paymentData: {
                  description: "Pagamento via site",
                  additional_info: {
                    items: order.items.map(({ currency_id, ...rest }) => rest),
                  },
                  ...formData,
                },
                preferenceId,
              });

              if (newOrder) {
                if (["ticket, bankTransfer"].includes(selectedPaymentMethod))
                  localStorage.setItem("paymentMethodWarning", true);
                localStorage.setItem("order", newOrder.number);
                history.push("/pedido-efetuado");
                return;
              }

              handleCancel();
              history.push("/erro");
            },
            onError: (error) => {
              handleCancel();
              console.error(error);
            },
          },
        };
        window.paymentBrickController = await bricksBuilder.create(
          "payment",
          "paymentBrick_container",
          settings
        );
      };

      renderPaymentBrick(bricksBuilder);

      setLoading(false);
    }
  };

  useEffect(() => {
    setFormValid(
      !!(
        cliente.nome &&
        cliente.sobrenome &&
        cliente.email &&
        cliente.telefone &&
        cliente.cpf &&
        endereco.logradouro &&
        endereco.numero &&
        endereco.localidade &&
        endereco.uf &&
        endereco.bairro
      )
    );
  }, [cliente, endereco]);

  useEffect(() => {
    if (location.product) addProduct(location.product);

    clearShipping();

    const script = document.createElement("script");
    script.src = "https://sdk.mercadopago.com/js/v2";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <S.CartContainer>
      <h1>Carrinho</h1>

      {cartData.length > 0 ? (
        <>
          {cartData.map((product, index) => (
            <CartProduct key={`product-${index}`} product={product} />
          ))}
          <Link to="/produtos">
            <Button className="continue-buying" type="primary">
              Continuar comprando
            </Button>
          </Link>
          <TextArea
            id="observacao"
            value={observacao}
            onChange={(event) => setObservacao(event.target.value)}
            label="Observações (Personalizações, cor, tamanho, informações sobre envio, etc):"
            placeholder="Ex: Enviar em pacotes separados."
          />
        </>
      ) : (
        <div className="empty-container">
          <h2>Seu carrinho está vazio</h2>
          <Button type="primary" onClick={() => history.push("/produtos")}>
            Escolher produtos
          </Button>
        </div>
      )}

      <S.InfoContainer>
        <div className="shipping-container">
          <p className="product-quantity">Calcular Frete:</p>
          <Input
            id="cep"
            value={cep}
            disabled={cepLoading}
            mask="99999-999"
            placeholder="CEP"
            onChange={(event) => setCep(event.target.value)}
            onKeyPress={(event) =>
              event.charCode === 13 ? handleCalcFrete() : null
            }
          />
          <Button id="btnCalcular" type="primary" onClick={handleCalcFrete} disabled={cartData.length === 0}>
            Calcular
          </Button>

          {alreadySearchedCep && shipping.options.length === 0 ? (
            <p>Não foi possível calcular o frete, tente novamente.</p>
          ) : (
            <Radio.Group
              onChange={handleChangeShipping}
              value={shipping.selected}
            >
              <Space direction="vertical">
                {shipping.options.map((option, index) => {
                  if (option.valor === 0) return <></>;
                  return (
                    <Radio key={`shipping-${index}`} value={index}>
                      {option.label}
                      {option.msgErro ? ` - Obs: ${option.msgErro}` : ""}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          )}
        </div>

        <div className="total-container">
          <p className={`total-price ${hasDiscount ? "has-discount" : ""}`}>
            Produtos:{" "}
            <b>{formatPrice.format(totalProductsPriceWithoutPromotions)}</b>
          </p>
          {hasDiscount && (
            <>
              <p className="discount-value">
                Desconto:{" "}
                <b>
                  {formatPrice.format(
                    totalProductsPriceWithoutPromotions - totalProductsPrice
                  )}
                </b>
              </p>
              <p className="total-price-with-discount">
                Produtos: <b>{formatPrice.format(totalProductsPrice)}</b>
              </p>
            </>
          )}
          <p>
            Frete: <b>{formatPrice.format(shipping.price)}</b>
          </p>
          <p>
            Total: <b>{formatPrice.format(orderPrice)}</b>
          </p>

          <Button
            onClick={handleOpenModal}
            disabled={cartData.length === 0 || shipping.selected === null}
            type="primary"
          >
            Continuar
          </Button>
        </div>
      </S.InfoContainer>

      <PageLoader loading={loading || cepLoading} />

      <Modal
        open={modalVisible}
        onOk={handleContinue}
        onCancel={handleCancel}
        footer={[
          <>
            {!onPayment && (
              <>
                <Button key="back" onClick={handleCancel}>
                  Cancelar
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleContinue}
                  disabled={!formValid}
                >
                  Confirmar
                </Button>
              </>
            )}
          </>,
        ]}
      >
        {!onPayment && (
          <>
            <S.Subtitle>Informações Pessoais</S.Subtitle>
            <Input
              id="nome"
              placeholder="Nome"
              value={cliente.nome}
              onChange={handleChangeCliente}
            />
            <Input
              id="sobrenome"
              placeholder="Sobrenome"
              value={cliente.sobrenome}
              onChange={handleChangeCliente}
            />
            <Input
              id="telefone"
              placeholder="Telefone"
              value={cliente.telefone}
              onChange={handleChangeCliente}
            />
            <Input
              id="email"
              placeholder="Email"
              value={cliente.email}
              onChange={handleChangeCliente}
            />
            <Input
              id="cpf"
              placeholder="CPF"
              value={cliente.cpf}
              onChange={handleChangeCliente}
            />
            <S.Subtitle>Endereço de entrega</S.Subtitle>
            <Input id="cep" value={cep} disabled={true} />
            <Input
              id="logradouro"
              placeholder="Logradouro"
              value={endereco.logradouro}
              onChange={handleChangeEndereco}
            />
            <Input
              id="numero"
              placeholder="Número"
              value={endereco.numero}
              onChange={handleChangeEndereco}
            />
            <Input
              id="complemento"
              placeholder="Complemento"
              value={endereco.complemento}
              onChange={handleChangeEndereco}
            />
            <Input
              id="bairro"
              placeholder="Bairro"
              value={endereco.bairro}
              onChange={handleChangeEndereco}
            />
            <Input
              id="cidade"
              placeholder="Cidade"
              value={endereco.localidade}
              onChange={handleChangeEndereco}
            />
            <Input
              id="uf"
              placeholder="UF"
              value={endereco.uf}
              onChange={handleChangeEndereco}
            />
          </>
        )}

        <div id="paymentBrick_container"></div>
      </Modal>
    </S.CartContainer>
  );
}

export default Cart;
