import styled from "styled-components";

export const OrderStatusContainer = styled.div`
  h1 {
    color: ${(props) => props.theme.corPrimaria};
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto 50px;
    padding: 0 20px;

    * {
      margin-bottom: 6px;
    }
  }

  .steps-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .ant-steps-item-icon {
    border-color: ${(props) => props.theme.corPrimaria} !important;
  }

  .ant-steps-item-wait .ant-steps-item-icon,
  .ant-steps-item-finish .ant-steps-item-icon {
    background: ${(props) => `${props.theme.corPrimaria}1A`} !important;
  }

  .loading-container {
    display: flex;
    justify-content: center;
  }
`;
