import styled from "styled-components";

export const PageContainer = styled.div`
  padding: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Logo = styled.img`
  width: 100px !important;
  margin-left: 10px;
  border: 1px solid ${(props) => props.theme.corPrimaria};
  padding: 10px;
`;
