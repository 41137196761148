import React from 'react';

import { Spin } from 'antd';

import * as S from './styles';

function PageLoader({ loading }) {
    if (!loading) return <></>;

    return (
        <S.OverlayContainer>
            <S.LoaderContainer>
                <Spin />
                <span>Carregando...</span>
            </S.LoaderContainer>
        </S.OverlayContainer>
    );
}

export default PageLoader;
