import React from "react";

import { Input } from "antd";

import { InputContainer } from "./styles";

const { TextArea } = Input;

function StyledTextArea(props) {
  return (
    <InputContainer>
      <p className="input-label">{props.label}</p>
      <TextArea rows={props.rows || 2} {...props} />
    </InputContainer>
  );
}

export default StyledTextArea;
