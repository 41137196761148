import styled from "styled-components";

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background: ${(props) => props.theme.corFundo};
  border-bottom: 1px solid;
  height: 130px;
  /* min-height: 65px; */
  width: 100%;
  overflow: hidden;

  #logo {
    max-width: 200px;
    min-height: 65px;
    margin: 6px 20px 0px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }

    h1 {
      font-size: 30px;
      color: ${(props) => props.theme.corPrimaria};
      border-bottom: 2px dashed ${(props) => props.theme.corPrimaria};
      display: inline;
    }
  }

  .itens-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  @media (max-width: 620px) {
    height: auto;

    img {
      max-width: 200px;
    }

    .itens-container {
      display: block;
      width: 100%;
      padding-top: 10px;

      .ant-collapse-header {
        display: none;
      }

      .anticon {
        position: absolute;
        top: 28px;
        right: 20px;
        cursor: pointer;
        font-size: 20px;

        svg {
          fill: ${(props) => props.theme.corPrimaria};
        }
      }

      .ant-collapse {
        text-align: center;
      }
    }
  }
`;

export const MenuContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;

  @media (max-width: 620px) {
    flex-direction: column;
  }
`;

export const MenuItem = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.corPrimaria};
  text-transform: uppercase;

  div {
    border-bottom: 2px solid transparent;
    min-width: 50px;
    margin: 0 30px;
    transition: border 0.3s ease-in-out;
  }

  @media (max-width: 620px) {
    div {
      width: fit-content;
      margin: 0 auto;
    }
  }

  :hover,
  &.active {
    div {
      border-color: ${(props) => props.theme.corPrimaria};
    }
  }
`;
