import styled from "styled-components";

export const MenuContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.corFundoMenu};
  border-top: 1px solid ${(props) => props.theme.corPrimaria};

  .itens-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    &.desk {
      padding: 6px 0 10px 0;
    }
  }

  @media (max-width: 900px) {
    height: auto;

    img {
      max-width: 200px;
    }

    .itens-container {
      flex-direction: column;
      width: 100%;

      .anticon-menu {
        display: none;
      }

      .ant-collapse-header {
        display: none;
      }

      .ant-collapse {
        text-align: center;
        border: none;
        background: transparent;
      }

      .ant-collapse-content,
      .ant-collapse-item {
        border: none;
      }

      .ant-collapse-content {
        background-color: transparent;
      }
    }
  }

  a {
    font-size: 22px;
    color: ${(props) => props.theme.corTextoMenu};
    border-bottom: 1px solid transparent;
    margin: 0 4vw;
    transition: border 0.3s ease-in-out;

    @media (max-width: 620px) {
      width: fit-content;
      margin: 0 auto;
    }

    :hover {
      border-color: ${(props) => props.theme.corTextoMenu};
    }
  }
`;
