import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import { verifySession } from "./services/session";

import Home from "./pages/Home";
import Showcase from "./pages/Showcase";
import About from "./pages/About";
import OrderStatus from "./pages/OrderStatus";
import Cart from "./pages/Cart/index";
import ProductDetails from "./pages/ProductDetails";
import OrderSuccess from "./pages/OrderSuccess";
import OrderFailed from "./pages/OrderFailed";
import ErrorPage from "./pages/ErrorPage";
import CancelOrder from "./pages/CancelOrder";
import Vacation from "./pages/Vacation";
import Maintenance from "./pages/Maintenance";

import AdminLogin from "./pages/Admin/Login";
import AdminHome from "./pages/Admin/Home";
import ProductList from "./pages/Admin/Products/List";
import ProductForm from "./pages/Admin/Products/Form";
import CategoryList from "./pages/Admin/Categories/List";
import CategoryForm from "./pages/Admin/Categories/Form";
import OrderList from "./pages/Admin/Orders/List";
import OrderForm from "./pages/Admin/Orders/Form";
import GeneralConfigs from "./pages/Admin/GeneralConfigs";

import NotFound from "./pages/NotFound";
import VisualConfigs from "./pages/Admin/VisualConfigs";
import Information from "./pages/Information/index";
import PromotionList from "./pages/Admin/Promotions/List";
import PromotionForm from "./pages/Admin/Promotions/Form";

const verifyToken = async (token) => {
  const lastTokenVerify = sessionStorage.getItem("lastTokenVerify");
  const TWO_MIN = 2 * 60 * 1000;

  if (!lastTokenVerify || new Date() - new Date(lastTokenVerify) > TWO_MIN) {
    sessionStorage.setItem("lastTokenVerify", new Date());
    const tokenValid = await verifySession(token);

    if (!tokenValid) {
      localStorage.removeItem("adminToken");
      sessionStorage.removeItem("lastTokenVerify");
      window.location.href = "/admin/login";
    }
  }
};

const validateAdminToken = () => {
  const token = localStorage.getItem("adminToken");

  if (!token) return false;

  verifyToken(token);
  return true;
};

function AdminRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        validateAdminToken() ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/admin/login"} />
        )
      }
    />
  );
}

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />

    <Route exact path="/produtos" component={Showcase} />
    <Route exact path="/quem-somos" component={About} />
    <Route exact path="/informacoes" component={Information} />
    <Route exact path="/pedidos" component={OrderStatus} />
    <Route exact path="/carrinho" component={Cart} />
    <Route exact path="/detalhes-produto/:id" component={ProductDetails} />

    <Route exact path="/pedido-efetuado" component={OrderSuccess} />
    <Route exact path="/pedido-falha" component={OrderFailed} />

    <Route exact path="/cancelar-pedido/:id" component={CancelOrder} />

    <Route exact path="/loja-em-manutencao" component={Maintenance} />
    <Route exact path="/loja-de-ferias" component={Vacation} />
    <Route exact path="/erro" component={ErrorPage} />

    {/* Admin */}
    <Route exact path="/admin/login" component={AdminLogin} />
    <AdminRoute exact path="/admin" component={AdminHome} />
    <AdminRoute exact path="/admin/produtos" component={ProductList} />
    <AdminRoute
      exact
      path="/admin/produtos/formulario"
      component={ProductForm}
    />
    <AdminRoute exact path="/admin/categorias" component={CategoryList} />
    <AdminRoute exact path="/admin/categorias/formulario" component={CategoryForm} />
    <AdminRoute exact path="/admin/pedidos" component={OrderList} />
    <AdminRoute exact path="/admin/pedidos/formulario" component={OrderForm} />
    <AdminRoute exact path="/admin/promocoes" component={PromotionList} />
    <AdminRoute exact path="/admin/promocoes/formulario" component={PromotionForm} />
    <AdminRoute
      exact
      path="/admin/configuracoes-visuais"
      component={VisualConfigs}
    />
    <AdminRoute
      exact
      path="/admin/configuracoes-gerais"
      component={GeneralConfigs}
    />
    {/* Fim Admin */}

    <Route component={NotFound} />
  </Switch>
);

export default Routes;
