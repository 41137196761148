import React from "react";

import { Result } from "antd";
import { SmileOutlined } from "@ant-design/icons";

const Maintenance = () => (
  <Result
    icon={<SmileOutlined />}
    title="Nossa loja está passando por uma manutenção para lhe atender cada vez melhor, voltamos em breve!"
  />
);

export default Maintenance;
