import styled from 'styled-components';

export const InputContainer = styled.div`
    .input-label {
      margin: 0px;
      font-size: 14px;
    }

    .ant-input-number {
      width: 100%;
    }
`;
