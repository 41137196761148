import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Logo = styled.img`
  width: 200px;
  margin-left: 10px;
  border: 1px solid ${(props) => props.theme.corPrimaria};
  padding: 10px;
`;

export const AdicionarBtn = styled.span`
  padding: 6px 20px;
  background: ${(props) => props.theme.corPrimaria};
  border-radius: 6px;
  color: #fff;
  margin-left: 10px;
`;
