import React from "react";

import { Result } from "antd";

const ErrorPage = () => {
  return (
    <Result
      status="error"
      title="Algo deu errado"
      subTitle="Erro ao carregar a página."
    />
  );
};

export default ErrorPage;
