import { React } from "react";

import { StyledCarousel } from "../../styles/global";

const CarouselHome = ({ images }) => {
  const jsonImages = images ? JSON.parse(images) : [];

  return (
    <StyledCarousel autoplay autoplaySpeed={5000}>
      {jsonImages.length > 0 &&
        jsonImages.map((image, index) => (
          <a key={`carousel-${index}`} href={image.link || "#"}>
            <img src={image.image} alt={image.image} />
          </a>
        ))}
    </StyledCarousel>
  );
};

export default CarouselHome;
