import { React } from "react";
import { Link } from "react-router-dom";

import { InputNumber } from "antd";

import { StyledContainer } from "./styles";

import { formatPrice } from "../../utils";

import { useCart } from "./../../contexts/Cart";
import { X } from "lucide-react";

function CartProduct({ product }) {
  const { removeProduct, addProduct, clearShipping } = useCart();

  const productToCalculatePrice = product.variant ? product.variant : product
  const price = formatPrice.format(productToCalculatePrice.price)
  const promotionalPrice = productToCalculatePrice.promotionalPrice ? formatPrice.format(productToCalculatePrice.promotionalPrice) : null

  const handleQuantityChange = (value) => {
    if (value === 0) {
      handleRemoveProduct(product)
      return
    }
    addProduct({
      ...product,
      quantity: value - product.quantity,
    });
    clearShipping();
  };

  const handleRemoveProduct = (product) => {
    removeProduct(product);
    clearShipping();
  };

  return (
    <StyledContainer>
      <Link
        to={{
          pathname: `/detalhes-produto/${product.code}`,
          product: product,
        }}
        className="name-container"
      >
        <img src={product.images[0]} alt={product.images[0]} />
        <p className="product-title">{product.name}</p>
      </Link>
      <p className="product-variant">{product.variant?.name}</p>
      <p className={`product-price ${promotionalPrice ? 'promotion-active' : ''}`}>
        <span className="price">{price}</span>
        {promotionalPrice && <span className="promotional-price">{promotionalPrice}</span>}
      </p>
      <InputNumber
        value={product.quantity}
        onChange={handleQuantityChange}
      />

      <X cursor="pointer" size={16} color="black" onClick={() => handleRemoveProduct(product)} />
    </StyledContainer>
  );
}

export default CartProduct;
