import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Form, Button, Row, Col } from "antd";

import Input from "../../../../components/Admin/Inputs/Input";

import { openNotification } from "../../../../utils";

import * as S from "./styles";
import { createCategorie, updateCategorie } from "../../../../services/categories";

function CategorieForm({ location: { categorie } }) {
  const [loading, setLoading] = useState(false);
  // const [iconeUrl, setIconeUrl] = useState(categorie?.icon);

  const history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);

    const body = { ...values };

    let resp;
    if (categorie) {
      body.id = categorie._id;

      resp = await updateCategorie(body);
    } else {
      resp = await createCategorie(body);
    }

    if (resp) {
      openNotification(
        "success",
        `Categoria ${categorie ? "atualizada" : "criada"} com sucesso.`
      );
      history.push("/admin/categorias");
      return;
    }

    openNotification(
      "error",
      `A categoria não pode ser ${
        categorie ? "atualizada" : "criada"
      }, por favor tente novamente.`
    );
    setLoading(false);
  };

  return (
    <S.PageContainer>
      <Row>
        <Col span={22}>
          <h1>{!categorie ? "Nova" : "Editar"} categoria</h1>
        </Col>

        <Col span={2}>
          <Button onClick={() => history.push("/admin/categorias")}>
            Voltar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={14}>
          <Form
            name="categorie"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ ...categorie }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Código"
              name="code"
              rules={[{ required: true, message: "Código é obrigatório" }]}
            >
              <Input disabled={categorie?.code} />
            </Form.Item>

            <Form.Item
              label="Nome"
              name="label"
              rules={[{ required: true, message: "Nome é obrigatório" }]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item 
              label="URL ícone" 
              name="icon"
            >
              <S.InputContainer>
                <Input
                  value={iconeUrl}
                  onChange={(event) => setIconeUrl(event.target.value)}
                />
                <S.Logo
                  style={{ width: "30px" }}
                  src={iconeUrl}
                  alt="IconePreview"
                />
              </S.InputContainer>
            </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {!categorie ? "Criar" : "Salvar"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </S.PageContainer>
  );
}

export default CategorieForm;
