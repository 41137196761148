import api from "./api";

const createOrder = async (order) => {
  try {
    const url = `/order`;

    order.user = process.env.REACT_APP_STORE_USER;

    const responseNewOrder = await api.post(url, order);

    if (responseNewOrder.status === 201) return responseNewOrder.data;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getOrder = async ({ cpf, orderNumber, id }) => {
  try {
    const query = [];

    if (cpf) query.push(`cpf=${cpf}`);
    if (orderNumber) query.push(`number=${orderNumber}`);
    if (id) query.push(`id=${id}`);

    const url = `/order?${query.join("&")}`;

    const responseRastreio = await api.get(url);

    if (responseRastreio.data) return responseRastreio.data;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getOrders = async () => {
  try {
    const token = localStorage.getItem("adminToken");

    const responseOrders = await api.get("/order", {
      headers: {
        authorization: token,
      },
    });

    if (responseOrders.data) return responseOrders.data;

    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const cancelOrder = async (id) => {
  try {
    const responseRastreio = await api.delete(`/order/${id}`);

    if (responseRastreio.status === 202) return true;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const updateOrder = async (body) => {
  try {
    const token = localStorage.getItem("adminToken");

    const { orderId } = body;

    delete body.orderId;

    const responseOrders = await api.put(`/order/${orderId}`, body, {
      headers: {
        authorization: token,
      },
    });

    if (responseOrders.data) return true;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const updatePayments = async () => {
  try {
    const responseUpdatePayments = await api.put("/checkAndUpdatePayments");

    if (responseUpdatePayments.data) return true;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export {
  createOrder,
  getOrder,
  cancelOrder,
  getOrders,
  updateOrder,
  updatePayments,
};
