/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ProductShelf from "./../../components/ProductShelf/index";

import { ShelfContainer, StyledSelect, FilterContainer } from "./styles";
import { Select } from "antd";

import { getProduct } from "../../services/products";
import { Spin } from "antd";
import { getCategorie } from "../../services/categories";

const { Option } = Select;

function Showcase() {
  const [searchedQuery, setSearchedQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("Recentes");
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [categories, setCategories] = useState([]);
  const [showingProducts, setShowingProducts] = useState([]);

  const location = useLocation();

  const removerAcentos = (newStringComAcento) =>
    newStringComAcento
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

  const loadProducts = async () => {
    let productsResponse = await getProduct();

    if (location.search.includes("q=")) {
      const query = new URLSearchParams(location.search)
        .get("q")
        .replaceAll("-", " ");

      setSearchedQuery(`Resultado de ${query}`);

      productsResponse = productsResponse.filter((product) =>
        removerAcentos(product.name).includes(removerAcentos(query))
      );
    }
    
    const responseCategories = await getCategorie();

    const newCategoriesCodes = [...new Set(productsResponse.reduce((arrCategories, product) => {
      if (product.categories) arrCategories.push(...product.categories.map(category => category.code));
      return arrCategories;
    }, []))]

    const newCategories = newCategoriesCodes.map(categoryCode => {
      return responseCategories.find(category => category.code === categoryCode)
    }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

    setProducts([...productsResponse]);
    setCategories(newCategories);
    setShowingProducts([...productsResponse]);
    setLoadingProducts(false);
  };

  const handleChangeCategory = (category) => {
    const newSelectedCategory = categories.find(cat => cat.code === category);
    setSelectedCategory(newSelectedCategory ?? '');
  };

  useEffect(() => {
    let newShowingProducts = [...products];

    const orderByReleaseDate = (a, b) => {
      if (a.releaseDate < b.releaseDate) {
        return -1;
      }
      if (a.releaseDate > b.releaseDate) {
        return 1;
      }
      return 0;
    };

    const orderByLowerPrice = (a, b) => {
      if (a.price < b.price) {
        return -1;
      }
      if (a.price > b.price) {
        return 1;
      }
      return 0;
    };

    if (selectedOrder === "Mais recentes") {
      newShowingProducts = newShowingProducts.map((product) => {
        product.releaseDate = new Date(product.releaseDate);
        return product;
      });
      newShowingProducts = newShowingProducts.sort(orderByReleaseDate);
      newShowingProducts = newShowingProducts.reverse();
    }

    if (selectedOrder === "Menor preço")
      newShowingProducts = newShowingProducts.sort(orderByLowerPrice);
    if (selectedOrder === "Maior preço") {
      newShowingProducts = newShowingProducts.sort(orderByLowerPrice);
      newShowingProducts = newShowingProducts.reverse();
    }

    if (selectedCategory) {
      newShowingProducts = newShowingProducts.filter(
        (product) => product.categories?.find(cat => cat.code === selectedCategory.code)
      );
    }

    setShowingProducts(newShowingProducts)
  }, [selectedCategory, selectedOrder]);

  useEffect(() => {
    loadProducts();
  }, [location.search]);

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <ShelfContainer>
      <FilterContainer>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ margin: "0 10px 10px 0", minWidth: '60px' }}>Filtrar:</p>
          <StyledSelect
            value={selectedCategory}
            onChange={handleChangeCategory}
          >
            <Option value="">Todas categorias...</Option>
            {categories.map((category) => (
              <Option key={category.label} value={category.code}>
                {category.label}
              </Option>
            ))}
          </StyledSelect>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ margin: "0 10px 10px 0", minWidth: '60px' }}>Ordenar:</p>
          <StyledSelect value={selectedOrder} onChange={setSelectedOrder}>
            <Option value="Mais recentes">Mais recentes</Option>
            <Option value="Menor preço">Menor preço</Option>
            <Option value="Maior preço">Maior preço</Option>
          </StyledSelect>
        </div>
      </FilterContainer>

      {loadingProducts ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <ProductShelf
          title={searchedQuery || selectedCategory?.label || selectedOrder}
          products={showingProducts}
        />
      )}
    </ShelfContainer>
  );
}

export default Showcase;
