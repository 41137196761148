import styled from "styled-components";

import { Select } from "antd";

export const ShelfContainer = styled.div`
  padding: 20px;

  .loading-container {
    display: flex;
    justify-content: center;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 250px;
  margin-bottom: 10px;
`;
