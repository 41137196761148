/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Result, Button } from "antd";
import { cancelOrder, getOrder } from "../../services/orders";
import PageLoader from "../../components/PageLoader";

const CancelOrder = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Caso precise de mais informações, favor entrar em contato com a loja."
  );
  const [canceled, setCanceled] = useState(false);
  const [order, setOrder] = useState({});

  const history = useHistory();
  const location = useLocation();

  async function handleCancel() {
    setLoading(true);
    const responseCancel = await cancelOrder(order._id);

    setLoading(false);

    if (!responseCancel) {
      setErrorMessage(
        "O pedido não pode mais ser cancelado pois já está em produção/separação, caso ainda queira cancelar ou precise de mais informações, favor entrar em contato com a loja."
      );
      setError(true);
      return;
    }

    setCanceled(true);
  }

  async function getOrderById() {
    const orderId = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );

    const orderFound = await getOrder({
      id: orderId,
    });

    setLoading(false);

    if (!orderFound) {
      setErrorMessage(
        "Não encontramos seu pedido, caso precise de mais informações, favor entrar em contato com a loja."
      );
      setError(true);
      return;
    }

    if (orderFound.status === 98 || orderFound.status === 99) {
      setErrorMessage(
        "Pedido já cancelado, caso precise de mais informações, favor entrar em contato com a loja."
      );
      setError(true);
      return;
    }

    setOrder(orderFound);
  }

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <>
      {!loading && !canceled && (
        <>
          {!order || error ? (
            <Result
              status="error"
              title="Algo deu errado"
              subTitle={errorMessage}
              extra={
                <Button type="primary" onClick={() => history.push("/")}>
                  Voltar ao início
                </Button>
              }
            />
          ) : (
            <Result
              title="Atenção"
              subTitle={`Deseja realmente cancelar o pedido ${order.number}?`}
              extra={[
                <Button
                  key="homeBtn"
                  type="primary"
                  onClick={() => history.push("/")}
                >
                  Voltar ao início
                </Button>,
                <Button key="cancelBtn" onClick={() => handleCancel()}>
                  Cancelar pedido
                </Button>,
              ]}
            />
          )}
        </>
      )}
      {!loading && canceled && (
        <Result
          status="success"
          title="Pedido cancelado"
          subTitle="Sentimos muito que tenha precisado cancelar seu pedido, ficamos a disposição."
          extra={
            <Button type="primary" onClick={() => history.push("/")}>
              Voltar ao início
            </Button>
          }
        />
      )}

      <PageLoader loading={loading} />
    </>
  );
};

export default CancelOrder;
