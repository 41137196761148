import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background: ${(props) => props.theme.corFundo};
  width: 100%;
  overflow: hidden;

  .top-banner-container {
    width: 100%;
    background-color: ${(props) => props.theme.corPrimaria};
    color: ${(props) => props.theme.corFundo};
    text-align: center;
    font-size: 14px;
    height: 30px;
    margin-top: 0px;
    transition: all 0.3s ease-in-out;
    padding: 4px 0;
    cursor: normal;
  }

  .content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    max-width: 1920px;
    min-height: 100px;
    width: 100%;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .menu-btn {
    display: none;
  }

  #logo {
    max-width: 186px;
    margin-left: 40px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .search-container {
    display: flex;
    align-items: center;

    input {
      text-align: center;
      color: ${(props) => props.theme.corTexto};
      border: none;
      background: transparent;
      border-bottom: 1px solid ${(props) => props.theme.corTexto};
      width: 350px;
      max-width: 100%;
      font-size: 16px;
      margin-right: 6px;
    }

    svg {
      cursor: pointer;
      margin-left: -34px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 220px;
    gap: 20px;
    margin-right: 40px;

    .action {
      display: flex;
      align-items: center;
      color: ${(props) => props.theme.corTexto};
      cursor: pointer;

      .notification {
        opacity: 1;
        width: 14px !important;
        height: 14px;
        background: ${(props) => props.theme.corPrimaria};
        color: ${(props) => props.theme.corFundo};
        border-radius: 50%;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -16px;
        margin-left: -16px;
      }

      svg {
        margin-right: 6px;
      }

      span {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        width: 0;
        font-size: 16px;
        overflow: hidden;
      }

      &:hover {
        span {
          width: 64px;
          opacity: 1;
        }
      }
    }
  }

  &.scrolled {
    .content-container {
      min-height: 60px;
    }

    .top-banner-container {
      margin-top: -30px;
    }
  }

  @media screen and (max-width: 1100px) {
    .action {
      span {
        width: 64px !important;
        opacity: 1 !important;
      }
    }
  }

  @media screen and (max-width: 900px) {
    height: auto;
    min-height: 80px;

    .menu-btn {
      transition: all 0.3s ease-in-out;
      background: transparent;
      cursor: pointer;
      display: block;
      border: none;
      position: absolute;
      top: 50px;
      left: 16px;
    }

    .actions {
      transition: all 0.3s ease-in-out;
      position: absolute;
      margin: 0;
      top: 50px;
      right: 16px;
      -webkit-box-pack: start;
      justify-content: flex-start;
      width: fit-content;

      .notification {
        margin-left: -10px !important;
      }

      .action {
        span {
          width: 0 !important;
        }

        svg {
          margin: 0;
        }

        :first-child {
          display: none;
        }
      }
    }

    &.scrolled {
      .actions,
      .menu-btn {
        top: 22px;
      }
      
      .search-container {
        height: 0px;
        overflow: hidden;
        margin: 0;
      }
    }

    #logo {
      margin: 24px 0px;
    }

    .search-container {
      width: 300px;
      margin-bottom: 10px;
      justify-content: center;

      input {
        width: 200px;
        border: none;
        padding-right: 30px;
      }
    }

    .content-container {
      flex-direction: column;
      min-height: unset;
    }
  }
`;
