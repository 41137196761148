import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin: 10px auto 0;
  padding-top: 10px;
  background: ${(props) => props.theme.corFundo};
  color: ${(props) => props.theme.corPrimaria};
  font-size: 20px;

  .pre-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    margin-bottom: 50px;
    background: ${(props) => props.theme.corFundoMenu};

    img {
      max-width: 185px;
    }
  }

  .infomation {
    text-align: center;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 6px;
    margin-top: 0;
    padding: 0 10px;
  }

  i {
    margin-right: 6px;
  }

  a {
    color: ${(props) => props.theme.corPrimaria};
  }

  .ant-layout-footer {
    background: #eee;
  }

  .developed-by {
    width: 100%;
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.corFundo};
    color: #433d3f;

    a {
      color: #433d3f;
      text-decoration: underline;
    }
  }
`;
