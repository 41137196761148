import { React } from "react";

import { FooterContainer } from "./styles";

import { Layout } from "antd";

import { useGlobal } from "../../contexts/Global";

import { Instagram, Mail, Phone } from "lucide-react";

const { Footer: FooterLayout } = Layout;

const Footer = () => {
  const { globalData: configs } = useGlobal();

  return (
    <FooterContainer>
      {configs.logo && (
        <div className="pre-footer">
          <img src={configs.logo} alt="logo" />
        </div>
      )}
      {configs.shortFooterDescription && (
        <p>{configs.shortFooterDescription}</p>
      )}
      <div className="infomation">
        {configs.telefone && (
          <p>
            <Phone color={configs.corTexto} />
            <a
              rel="noreferrer"
              target="_blank"
              href={`tel:${configs.telefone.replace(/\D/g, "")}`}
            >
              {configs.telefone}
            </a>
          </p>
        )}
        {configs.email && (
          <p>
            <Mail color={configs.corTexto} />
            <a
              rel="noreferrer"
              target="_blank"
              href={`mailto:${configs.email}`}
            >
              {configs.email}
            </a>
          </p>
        )}
        {configs.instagram && (
          <p>
            <Instagram color={configs.corTexto} />
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://www.instagram.com/${configs.instagram}/`}
            >
              @{configs.instagram}
            </a>
          </p>
        )}
      </div>
      <FooterLayout className="developed-by">
        <span>
          Developed by{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/felipe-rocha-558791124/"
          >
            Felipe Cortes
          </a>{" "}
          and{" "}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/in/yasmine-silva-a16a11127/"
          >
            Yasmine Silva
          </a>
        </span>
      </FooterLayout>
    </FooterContainer>
  );
};

export default Footer;
