import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Row, Col, Table, Space, Button, Popconfirm, Input } from "antd";

import { getCategories, deleteCategorie } from "../../../../services/categories";
import { openNotification } from "../../../../utils";

import * as S from "./styles";
import moment from "moment";

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingDeleteCategorie, setLoadingDeleteCategorie] = useState(false);

  const [nameFilter, setNameFilter] = useState("");
  const [sortedInfo, setSortedInfo] = useState({});

  const history = useHistory();

  const columns = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code - b.code,
      sortOrder: sortedInfo.columnKey === "code" ? sortedInfo.order : null,
    },
    {
      title: "Nome",
      dataIndex: "label",
      key: "label",
      sorter: (a, b) => a.label.length - b.label.length,
      sortOrder: sortedInfo.columnKey === "label" ? sortedInfo.order : null,
    },
    {
      title: "Atualizada em",
      dataIndex: "updatedAt",
      key: "updatedAt",
      responsive: ["md"],
      render: (_, record) => <span>{moment(record.updatedAt).format("DD/MM/YYYY HH:mm")}</span>,
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      sortOrder: sortedInfo.columnKey === "updatedAt" ? sortedInfo.order : null,
    },
    {
      title: "Operações",
      dataIndex: "operations",
      render: (_, record) =>
        categories.length > 0 ? (
          <Space size="middle">
            <Button type="primary" onClick={() => handleEdit(record.index)}>
              Editar
            </Button>
            <Popconfirm
              title="Tem certeza que deseja deletar?"
              onConfirm={() => handleDelete(record.index)}
            >
              <Button disabled={loadingDeleteCategorie}>Deletar</Button>
            </Popconfirm>
          </Space>
        ) : null,
    },
  ];

  const handleChange = (paginagion, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleEdit = (index) => {
    history.push({
      pathname: "/admin/categorias/formulario",
      categorie: categories[index],
    });
  };

  const handleDelete = async (index) => {
    setLoadingDeleteCategorie(true);

    const deletedResponse = await deleteCategorie(categories[index]._id);

    if (deletedResponse) {
      let newCategories = [...categories];
      newCategories.splice(index, 1);

      newCategories = newCategories.map((category, index) => {
        category.index = index;
        return category;
      });

      setCategories(newCategories);
      setFilteredCategories(newCategories);
      setNameFilter("");
      openNotification("success", "Categoria deletada com sucesso.");
    } else {
      openNotification(
        "error",
        "A categoria não pode ser deletada, por favor tente novamente."
      );
    }

    setLoadingDeleteCategorie(false);
  };

  const loadCategories = async () => {
    let categories = await getCategories();

    categories = categories.map((categorie, index) => {
      categorie.index = index;
      categorie.key = `categorie-${index}`;
      return categorie;
    });

    setCategories(categories);
    setFilteredCategories(categories);
    setLoadingCategories(false);
  };

  const handleFilterName = () => {
    setFilteredCategories(
      categories.filter((categorie) =>
        categorie.label.toLowerCase().includes(nameFilter.toLowerCase())
      )
    );
  };

  const handleInputNameChange = (e) => {
    if (e.code === "Enter") {
      handleFilterName();
      return;
    }
    setNameFilter(e.target.value);
  };

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <S.PageContainer>
      <Row align="middle" justify="space-between">
        <Row align="middle">
          <h1>Categorias</h1>
          <S.NameFilter>
            <Input
              value={nameFilter}
              onChange={handleInputNameChange}
              onKeyDown={handleInputNameChange}
              placeholder="Digite o nome da categoria"
            />
            <Button type="primary" onClick={handleFilterName}>
              Filtrar
            </Button>
          </S.NameFilter>
        </Row>
        <Link to="/admin/categorias/formulario">
          <Button type="primary">Nova Categoria</Button>
        </Link>
      </Row>
      <Row justify="center">
        <Col xs={24}>
          <Table
            scroll={{x: 300}}
            columns={columns}
            dataSource={filteredCategories}
            onChange={handleChange}
            loading={loadingCategories || loadingDeleteCategorie}
          />
        </Col>
      </Row>
    </S.PageContainer>
  );
}

export default CategoryList;
