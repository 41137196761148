/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";
import { useGlobal } from "./../../../contexts/Global";
import Input from "../../../components/Admin/Inputs/Input";
import { Button } from "antd";
import PageLoader from "./../../../components/PageLoader/index";
import { createSession } from "../../../services/session";

function AdminLogin() {
  const { globalData: configs } = useGlobal();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleLogin = async () => {
    if ((user, password)) {
      setLoading(true);

      const newToken = await createSession(user, password);

      setLoading(false);

      if (!newToken) {
        setError("Usuário ou senha inválidos");
        return;
      }

      localStorage.setItem("adminToken", newToken);
      history.push("/admin");
    }
  };

  return (
    <S.LoginContainer>
      <S.InfoContainer>
        {configs.logo && <img src={configs.logo} alt="Logo" />}

        <Input
          value={user}
          placeholder="Usuário"
          onChange={(e) => setUser(e.target.value)}
        />
        <Input
          value={password}
          placeholder="Senha"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter") handleLogin();
          }}
        />
        <span className="error-msg">{error}</span>

        <Button type="primary" onClick={handleLogin}>
          Acessar
        </Button>
      </S.InfoContainer>

      <PageLoader loading={loading} />
    </S.LoginContainer>
  );
}

export default AdminLogin;
