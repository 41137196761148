import { React, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { MenuOutlined } from "@ant-design/icons";
import { Collapse } from "antd";

import { MenuContainer, MenuContentContainer, MenuItem } from "./styles";

import { useGlobal } from "../../../contexts/Global";

const { Panel } = Collapse;

function Menu() {
  const { globalData: configs } = useGlobal();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuChange = () => {
    if (menuOpen) setMenuOpen(false);
    else setMenuOpen("menu");
  };

  const menuItems = [
    {
      label: "Início",
      link: "/admin",
    },
    {
      label: "Produtos",
      link: "/admin/produtos",
    },
    {
      label: "Categorias",
      link: "/admin/categorias",
    },
    {
      label: "Pedidos",
      link: "/admin/pedidos",
    },
    {
      label: "Promoções",
      link: "/admin/promocoes",
    },
    {
      label: "Visual",
      link: "/admin/configuracoes-visuais",
    },
    {
      label: "Geral",
      link: "/admin/configuracoes-gerais",
    },
  ];

  const location = useLocation();

  const MenuItens = () => {
    return (
      <div className="itens-container" onClick={() => setMenuOpen(false)}>
        {menuItems.map((item) => (
          <Link key={`menu-item-${item.label}`} to={item.link}>
            <MenuItem
              className={location.pathname === item.link ? "active" : ""}
            >
              <div>
                <span>{item.label}</span>
              </div>
            </MenuItem>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <MenuContainer>
      <MenuContentContainer>
        <Link id="logo" to="/admin" onClick={() => setMenuOpen(false)}>
          {configs?.logo && <img alt="logo" src={configs?.logo} />}
        </Link>

        <div className="itens-container hide-mobile">
          <MenuItens />
        </div>

        <div className="itens-container hide-desktop">
          <Collapse activeKey={menuOpen} onChange={handleMenuChange}>
            <Panel key="menu">
              <MenuItens />
            </Panel>
          </Collapse>

          <MenuOutlined onClick={handleMenuChange} />
        </div>
      </MenuContentContainer>
    </MenuContainer>
  );
}

export default Menu;
