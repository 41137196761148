import styled from "styled-components";

import { Carousel } from "antd";

export const StyledCarousel = styled(Carousel)`
  margin-bottom: 12px;

  .slick-slide {
    div {
      text-align: center;
    }
  }

  .image-container {
    min-height: 500px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  a,
  img {
    max-width: 1920px;
    width: 100%;
    max-height: 600px;
  }

  .slick-dots {
    margin-bottom: 0px;
    z-index: 1;

    li {
      background: #666666;

      &.slick-active button {
        background: ${(props) => props.theme.corPrimaria};
      }
    }
  }
`;
