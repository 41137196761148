import styled from "styled-components";

export const PageContainer = styled.div`
  padding: 20px;
`;

export const NameFilter = styled.div`
  display: flex;
  flex-wrap: wrap;

  input {
    margin: 0 10px 0 20px;
    width: 200px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 10px 0;

    button {
      width: 100%;
      margin: 10px 0;
    }
  }
`;
