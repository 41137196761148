import styled from "styled-components";

export const ProductContainer = styled.div`
  color: #777;
  padding: 20px;

  .ant-divider-vertical {
    height: 100%;
  }

  .ant-carousel {
    max-width: 500px;
    max-height: 500px;
    margin: 0 auto;

    img {
      width: auto !important;
      max-height: 500px;
      max-width: 100%;

      @media (max-width: 620px) {
        max-height: 400px;
      }

      @media (max-width: 320px) {
        max-height: 350px;
      }
    }
  }

  .product-title {
    margin: 0px;
    font-size: 20px;
    color: ${(props) => props.theme.corTexto};
    font-weight: bold;
  }

  .product-code {
    margin-top: 10px;
    font-size: 12px;
    color: "#000000";
    opacity: 0.7;
  }

  .product-short-description {
    margin: 0px;
    font-size: 14px;
  }

  .add-cart {
    width: 100% !important;
    max-width: 500px;
    font-size: 18px;
    height: auto;
  }

  .ant-input-number {
    margin-bottom: 6px;
    width: auto;
  }

  #quantity {
    width: 70px;
  }

  .product-quantity {
    margin: 0px;
    font-size: 14px;
  }

  .product-price {
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.corPrimaria};
    margin-bottom: 10px;

    &.promotion-active {
      .price {
        font-size: 12px;
        font-weight: normal;
        color: #666;
        text-decoration: line-through;
      }

      .promotional-price {
        margin-left: 8px;
      }
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;

    .shipping-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;

      div {
        display: flex;
        align-items: center;
      }

      input {
        margin-left: 6px;
      }

      #cep,
      #btnCalcular {
        max-width: 194px;
      }
    }
  }

  .ant-radio-group {
    margin: 10px 0px;
  }

  .ant-btn.ant-btn-primary {
    width: fit-content;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 0 20px;

  .description-title {
    font-size: 30px;
    color: ${(props) => props.theme.corPrimaria};
  }

  .description-content {
    font-size: 16px;
    color: ${(props) => props.theme.corTexto};
    white-space: pre-wrap;

    p, ul, h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }
  }
`;
