/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState, useEffect } from "react";
import { cloneObject } from "../utils";

const CartContext = createContext({});

export function useCart() {
  const context = useContext(CartContext);

  return context;
}

const initialShipping = {
  options: [],
  selected: null,
  price: 0,
};

export function CartProvider({ children }) {
  const [cartData, setCartData] = useState([]);
  const [totalProductsPrice, setTotalProductsPrice] = useState(0);
  const [totalProductsPriceWithoutPromotions, setTotalProductsPriceWithoutPromotions] = useState(0);
  const [shipping, setShipping] = useState(initialShipping);
  const [orderPrice, setOrderPrice] = useState(0);

  useEffect(() => {
    let localDataCart = localStorage.getItem(
      "cartData",
      JSON.stringify(cartData)
    );

    if (localDataCart) {
      localDataCart = JSON.parse(localDataCart);

      setCartData(localDataCart);
    }
  }, []);

  useEffect(() => {
    const totalCartPrice = cartData.reduce((total, product) => {
      return (total += (product.variant ? product.variant.promotionalPrice ?? product.variant.price : product.promotionalPrice ?? product.price) * product.quantity);
    }, 0);

    const totalCartPriceWithoutPromotions = cartData.reduce((total, product) => {
      return (total += (product.variant ? product.variant.price : product.price) * product.quantity);
    }, 0);

    setTotalProductsPrice(totalCartPrice);
    setTotalProductsPriceWithoutPromotions(totalCartPriceWithoutPromotions);
    setOrderPrice(totalCartPrice + parseFloat(shipping.price));
  }, [cartData, shipping.price]);

  function updateCartData(newCartData) {
    setCartData(newCartData);
    localStorage.setItem("cartData", JSON.stringify(newCartData));
  }

  function addProduct(product) {
    const productFoundIndex = cartData.findIndex(
      (cartProduct) => {
        if (cartProduct.code === product.code) {
          if (!product.variant) return true
          if (cartProduct.variant?.name === product.variant?.name) return true
          return false
        }
        return false
      }
    );

    let newCartData = [...cartData, cloneObject(product)];

    if (productFoundIndex !== -1) {
      newCartData.pop();
      newCartData[productFoundIndex].quantity += product.quantity;
    }

    updateCartData(newCartData);
  }

  function removeProduct(product) {
    const newCartData = cloneObject(cartData).filter(
      (cartProduct) => {
        if (cartProduct.code === product.code) {
          if (!product.variant) return false
          if (cartProduct.variant?.name === product.variant?.name) return false
          return true
        }
        return true
      }
    );

    updateCartData(newCartData);
  }

  function clearCart() {
    setCartData([]);
  }

  function clearShipping() {
    setShipping(initialShipping);
  }

  const contextValues = {
    cartData,
    totalProductsPrice,
    totalProductsPriceWithoutPromotions,
    setShipping,
    shipping,
    clearShipping,
    orderPrice,
    addProduct,
    removeProduct,
    setCartData,
    clearCart,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
}
