import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: #fff;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: #ccc;
  border-radius: 20px;
  padding: 14px;

  img {
    width: 80%;
    margin-bottom: 20px;
  }

  .error-msg {
    color: crimson;
  }
`;
