import styled from "styled-components";

import InputMask from "react-input-mask";

export const InputContainer = styled.div`
  .input-label {
    margin: 0px;
    font-size: 14px;
  }
`;

export const StylesInputMask = styled(InputMask)`
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  margin-bottom: 6px;
  width: 100%;

  :hover,
  :active,
  :focus {
    border-color: ${(props) => props.theme.corPrimaria};
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(150, 183, 108, 0.2);
  }
`;
