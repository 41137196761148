import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { Row, Col, Table, Space, Button, Tag } from "antd";

import { getOrders, updatePayments } from "../../../../services/orders";

import { openNotification } from "../../../../utils";

function OrderList() {
  const [orders, setOrders] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(true);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const history = useHistory();

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return {
          value: status,
          text: "Efetuado",
          name: "Efetuado",
          color: "default",
        };
      case 2:
        return {
          value: status,
          text: "Pagamento Aprovado",
          name: "Pagamento Aprovado",
          color: "success",
        };
      case 3:
        return {
          value: status,
          text: "Em andamento",
          name: "Em andamento",
          color: "processing",
        };
      case 4:
        return {
          value: status,
          text: "Enviado",
          name: "Enviado",
          color: "yellow",
        };
      case 5:
        return {
          value: status,
          text: "Finalizado",
          name: "Finalizado",
          color: "success",
        };
      case 98:
        return {
          value: status,
          text: "Cancelado pelo cliente",
          name: "Cancelado pelo cliente",
          color: "error",
        };
      case 99:
        return {
          value: status,
          text: "Cancelado pela loja",
          name: "Cancelado pela loja",
          color: "error",
        };
      default:
        return {
          name: "Status inválido",
          color: "error",
        };
    }
  };

  const handleChange = (_, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Número",
      dataIndex: "number",
      sorter: (a, b) => a.number - b.number,
      sortOrder: sortedInfo.columnKey === "number" ? sortedInfo.order : null,
      key: "number",
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        getStatus(1),
        getStatus(2),
        getStatus(3),
        getStatus(4),
        getStatus(5),
        getStatus(98),
        getStatus(99),
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status - b.status,
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      key: "status",
      render: (_, record) => {
        const status = getStatus(record.status);
        return <Tag color={status.color}>{status.name}</Tag>;
      },
    },
    {
      title: "Data do pedido",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortOrder: sortedInfo.columnKey === "date" ? sortedInfo.order : null,
      responsive: ["md"],
      render: (_, record) => (
        <span>{moment(record.date).format("DD/MM/YYYY HH:mm")}</span>
      ),
    },
    {
      title: "Operações",
      dataIndex: "operations",
      render: (_, record) =>
        orders.length > 0 ? (
          <Space size="middle">
            <Button type="primary" onClick={() => handleEdit(record.index)}>
              Editar
            </Button>
          </Space>
        ) : null,
    },
  ];

  const handleEdit = (index) => {
    history.push({
      pathname: "/admin/pedidos/formulario",
      order: orders[index],
    });
  };

  const loadOrders = async () => {
    setLoadingOrders(true);

    let orders = await getOrders();

    orders = orders.sort((a, b) =>
      a.status > b.status ? 1 : b.status > a.status ? -1 : 0
    );

    orders = orders.map((order, index) => {
      order.index = index;
      order.key = `order-${index}`;
      return order;
    });

    setOrders(orders);
    setLoadingOrders(false);
  };

  const handleCheckPayments = async () => {
    const paymentsUpdated = await updatePayments();

    if (paymentsUpdated) {
      openNotification(
        "success",
        "Os pagamentos foram atualizados com sucesso."
      );
      loadOrders();
      return;
    }

    openNotification(
      "error",
      "Os pagamentos não puderam ser atualizados, por favor tente novamente dentro de alguns minutos."
    );
  };

  useEffect(() => {
    loadOrders();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <Row justify="space-between">
        <h1>Pedidos</h1>
        <Button type="primary" onClick={handleCheckPayments}>
          Checar pagamentos
        </Button>
      </Row>
      <Row justify="center">
        <Col xs={24}>
          <Table
            scroll={{x: 300}}
            columns={columns}
            dataSource={orders}
            onChange={handleChange}
            loading={loadingOrders}
          />
        </Col>
      </Row>
    </div>
  );
}

export default OrderList;
