import React from "react";

import { Col } from "antd";

import { AboutContainer } from "./styles";
import { useGlobal } from "./../../contexts/Global";

const About = () => {
  const { globalData: configs } = useGlobal();

  return (
    <AboutContainer>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <h4>Quem somos</h4>
        <p>{configs.quemSomos}</p>
      </Col>
    </AboutContainer>
  );
};

export default About;
