import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, useHistory } from "react-router-dom";

import { Layout, Affix, ConfigProvider } from "antd";
import locale from 'antd/locale/pt_BR';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ThemeProvider } from "styled-components";

import AdminMenu from "./components/Admin/Menu";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import Routes from "./routes";

import "./styles/App.less";
import PageLoader from "./components/PageLoader";
import { getConfigs } from "./utils";
import { GlobalProvider, useGlobal } from "./contexts/Global";
import Header from "./components/Header";
import { CartProvider } from "./contexts/Cart";

const { Content } = Layout;

dayjs.locale('pt-br');

function App() {
  const { globalData, setGlobalData } = useGlobal();
  const history = useHistory();

  const [loadingConfigs, setLoadingConfigs] = useState(true);

  useEffect(() => {
    const loadConfigs = async () => {
      setLoadingConfigs(true);

      const responseConfigs = await getConfigs();

      setLoadingConfigs(false);

      if (!responseConfigs && !window.location.href.includes("admin")) {
        history.push("/loja-em-manutencao");
        return;
      }

      setGlobalData(responseConfigs);

      if (window.location.href.includes("admin")) return;

      if (responseConfigs.lojaEmManutencao) {
        history.push("/loja-em-manutencao");
      }

      if (responseConfigs.lojaDeFerias) {
        history.push("/loja-de-ferias");
      }
    };

    loadConfigs();
  }, [history, setGlobalData]);

  useEffect(() => {
    // Timeout para limpar cache a cada 3 minutos
    setTimeout(() => { sessionStorage.clear(); }, (3 * 60 * 1000));
  }, [])

  return (
    <div id="App">
      <ThemeProvider theme={globalData || {}}>
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: globalData?.corPrimaria || "#072f70",
            },
          }}
        >
          <CartProvider>
            <ScrollToTop>
              <Layout
                className="layout"
                style={{ background: globalData.corFundo }}
              >
                <Affix>
                  {window.location.href.includes("admin") ? (
                    <AdminMenu />
                  ) : (
                    <Header />
                  )}
                </Affix>

                <Content style={{ background: globalData.corFundo }}>
                  <div
                    className="site-layout-content"
                    style={{ background: globalData.corFundo }}
                  >
                    <PageLoader loading={loadingConfigs} />
                    <Routes />
                  </div>
                </Content>

                {!window.location.href.includes("admin") && <Footer />}
              </Layout>
            </ScrollToTop>
          </CartProvider>
        </ConfigProvider>
      </ThemeProvider>
    </div>
  );
}

const AppWrapper = () => {
  return (
    <GlobalProvider>
      <Router>
        <App />
      </Router>
    </GlobalProvider>
  );
};

export default AppWrapper;
