import styled from "styled-components";

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h4 {
    font-size: 30px;
    color: ${(props) => props.theme.corPrimaria};
  }

  p {
    font-size: 16px;
    color: ${(props) => props.theme.corTexto};
  }
`;
