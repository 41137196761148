import { decriptData, encriptData } from "../utils";
import api from "./api";

const getCategorie = async (code) => {
  try {
    const sessionCategories = sessionStorage.getItem("c");
    if (sessionCategories) {
      const categories = decriptData(sessionCategories);
      if (!code) return categories;

      const foundCategory = categories.find((category) => category.code === code);

      if (foundCategory) return foundCategory;
    }

    const url = `/category${code ? `?code=${code}` : ""}`;

    const responseCategory = await api.get(url);

    if (responseCategory.data) {
      if (!code) sessionStorage.setItem("c", encriptData(responseCategory.data));
      return responseCategory.data;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getCategories = async () => {
  try {
    const token = localStorage.getItem("adminToken");

    const responseCategories = await api.get("/category", {
      headers: {
        authorization: token,
      },
    });

    if (responseCategories.data) return responseCategories.data;

    return [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createCategorie = async (body) => {
  try {
    const token = localStorage.getItem("adminToken");

    const createdProduct = await api.post(
      "/category",
      {
        user: process.env.REACT_APP_STORE_USER,
        ...body,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );

    if (createdProduct.data) return createdProduct.data;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const updateCategorie = async (body) => {
  try {
    const { id } = body;

    if (id) {
      delete body.id;

      const token = localStorage.getItem("adminToken");

      const updatedCategory = await api.put(`/category/${id}`, body, {
        headers: {
          authorization: token,
        },
      });

      if (updatedCategory.data) return updatedCategory.data;
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const deleteCategorie = async (id) => {
  try {
    const token = localStorage.getItem("adminToken");

    const deletedCategory = await api.delete(`/category/${id}`, {
      headers: {
        authorization: token,
      },
    });

    if (deletedCategory.status === 204) return true;

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export { getCategorie, getCategories, createCategorie, updateCategorie, deleteCategorie };
