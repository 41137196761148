/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Result, Button } from "antd";
import { useCart } from "../../contexts/Cart";
import PageLoader from "../../components/PageLoader";

import * as S from "./styles";

const OrderSuccess = () => {
  const { clearCart, clearShipping } = useCart();
  const [orderLoading, setOrderLoading] = useState(true);
  const [orderNumber, setOrderNumber] = useState("");
  const [showPaymentMethodWarning, setShowPaymentMethodWarning] =
    useState(false);

  const history = useHistory();

  useEffect(() => {
    const order = localStorage.getItem("order");

    if (!order) {
      history.push("erro");
      return;
    }

    const paymentMethodWarning = localStorage.getItem("paymentMethodWarning");
    if (paymentMethodWarning) {
      setShowPaymentMethodWarning(true);
      localStorage.removeItem("paymentMethodWarning");
    }

    setOrderLoading(false);
    setOrderNumber(order);
    clearShipping();
    clearCart();
    localStorage.removeItem("order");
  }, []);

  return (
    <S.Container>
      <Result
        status="success"
        title="Pedido efetuado com sucesso."
        subTitle={`Número do pedido: ${orderNumber || ""}`}
      />
      <S.Paragraph>
        {showPaymentMethodWarning &&
          "Em breve você receberá um email do MercadoPago com as instruções de pagamento."}
        <br />
        Através do número do pedido você pode acompanhar o andamento na página
        'PEDIDOS', você também deve receber um email com essa e outras
        informações.
      </S.Paragraph>
      <Button type="primary" onClick={() => history.push("/")}>
        Continuar comprando
      </Button>
      <PageLoader loading={orderLoading} />
    </S.Container>
  );
};

export default OrderSuccess;
