import styled from "styled-components";

export const ShelfContainer = styled.div`
  margin-bottom: 12px;

  h2 {
    color: ${(props) => props.theme.corPrimaria};
    font-size: 32px;
    text-align: center;
    margin: 0px;
  }
`;

export const ProductsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
