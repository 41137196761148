import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import dayjs from 'dayjs';
import { Form, Button, Row, Col, Checkbox, DatePicker, InputNumber } from "antd";

import Input from "../../../../components/Admin/Inputs/Input";

import { openNotification } from "../../../../utils";

import * as S from "./styles";
import { createPromotion, updatePromotion } from "../../../../services/promotions";
import { DeleteFilled } from "@ant-design/icons";

const { RangePicker } = DatePicker;

function PromotionForm({ location: { promotion } }) {
  const [loading, setLoading] = useState(false);
  const [newProduct, setNewProduct] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [products, setProducts] = useState(promotion ? promotion.products : [])
  const [categories, setCategories] = useState(promotion ? promotion.categories : [])
  const [dates, setDates] = useState(promotion ? [dayjs(promotion?.startDate), dayjs(promotion?.endDate)] : []);

  const history = useHistory();

  const handleDatesChange = (dates) => {
    setDates([dates[0].toISOString(), dates[1].toISOString()])
  }

  const handleAddProduct = () => {
    if (newProduct) {
      setProducts([...products, newProduct])
      setNewProduct('')
    }
  }
  
  const handleAddCategory = () => {
    if (newCategory) {
      setCategories([...categories, newCategory])
      setNewCategory('')
    }
  }

  const onFinish = async (values) => {
    setLoading(true);

    const body = { 
      ...values, 
      startDate: dates[0],
      endDate: dates[1],
      products,
      categories: categories.filter(category => !isNaN(category)).map(category => parseInt(category))
    };

    let resp;
    if (promotion) {
      body.id = promotion?._id;

      resp = await updatePromotion(body);
    } else {
      resp = await createPromotion(body);
    }

    if (resp) {
      openNotification(
        "success",
        `Promoção ${promotion ? "atualizada" : "criada"} com sucesso.`
      );
      history.push("/admin/promocoes");
      return;
    }

    openNotification(
      "error",
      `A promoção não pode ser ${
        promotion ? "atualizada" : "criada"
      }, por favor tente novamente.`
    );
    setLoading(false);
  };

  return (
    <S.PageContainer>
      <Row>
        <Col span={22}>
          <h1>{!promotion ? "Nova" : "Editar"} promoção</h1>
        </Col>

        <Col span={2}>
          <Button onClick={() => history.push("/admin/promocoes")}>
            Voltar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={14}>
          <Form
            name="promotion"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ ...promotion, startDate: new Date(promotion?.startDate) }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: "Nome é obrigatório" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Texto da tag (Opicional)"
              name="customLabel"
            >
              <Input maxLength={12} />
            </Form.Item>

            <Form.Item
              name="active"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Ativa</Checkbox>
            </Form.Item>

            <Form.Item
              label="% de desconto"
              name="percentage"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Data de início e fim"
            >
              <RangePicker 
                defaultValue={dates}
                format={'DD/MM/YYYY'}
                onChange={handleDatesChange}
              />
            </Form.Item>

            <Form.Item
              name="applyToAllCatalog"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>Aplicar para todo o catalogo</Checkbox>
            </Form.Item>

            <Form.Item label="Produtos">
              <S.InputContainer>
                <Input
                  value={newProduct}
                  onChange={(event) => setNewProduct(event.target.value)}
                />
                <S.AdicionarBtn onClick={() => handleAddProduct()}>
                  Adicionar
                </S.AdicionarBtn>
              </S.InputContainer>

              {products.map((product, index) => (
                <div>
                  <DeleteFilled onClick={() => {
                    const newProducts = [...products]
                    newProducts.splice(index, 1)
                    setProducts(newProducts)
                  }} />
                  <span style={{marginLeft: '6px'}}>{product}</span>
                </div>
              ))}
            </Form.Item>

            <Form.Item label="Categorias">
              <S.InputContainer>
                <Input
                  value={newCategory}
                  onChange={(event) => setNewCategory(event.target.value)}
                />
                <S.AdicionarBtn onClick={() => handleAddCategory()}>
                  Adicionar
                </S.AdicionarBtn>
              </S.InputContainer>

              {categories.map((category, index) => (
                <div>
                  <DeleteFilled onClick={() => {
                    const newCategories = [...categories]
                    newCategories.splice(index, 1)
                    setCategories(newCategories)
                  }} />
                  <span style={{marginLeft: '6px'}}>{category}</span>
                </div>
              ))}
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                {!promotion ? "Criar" : "Salvar"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </S.PageContainer>
  );
}

export default PromotionForm;
