import styled from 'styled-components';

export const InputContainer = styled.div`
    max-width: 500px;
    margin-left: 6px;
    
    .input-label {
      margin: 0px;
      font-size: 14px;
    }
`;
