import styled from "styled-components";

export const PageContainer = styled.div`
  padding: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const AdicionarBtn = styled.span`
  padding: 6px 20px;
  background: ${(props) => props.theme.corPrimaria};
  border-radius: 6px;
  color: #fff;
  margin-left: 10px;
`;

