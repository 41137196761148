import { Fragment, React } from "react";

import ProductCard from "./../ProductCard/index";

import { ShelfContainer, ProductsContainer } from "./styles";

const ProductShelf = ({ title, products }) => {
  return (
    <ShelfContainer>
      <h2>{title}</h2>
      <ProductsContainer>
        {products.length > 0 ? (
          products.map((product, index) => (
            <Fragment key={`${product.name}-${index}`}>
              {product.name && product.price && product.images.length > 0 && 
                <ProductCard product={product} />
              }
            </Fragment>
          ))
        ) : (
          <p>Nenhum produto encontrado...</p>
        )}
      </ProductsContainer>
    </ShelfContainer>
  );
}

export default ProductShelf;
