import { React, useMemo } from "react";

import { Link } from "react-router-dom";

import { MenuContainer } from "./styles";

import { MenuOutlined } from "@ant-design/icons";

import { Collapse } from "antd";
import { useGlobal } from "../../contexts/Global";

const { Panel } = Collapse;

function Menu({ open = false, setMenuOpen, handleMenuChange }) {
  const { globalData: configs } = useGlobal();

  const menuItems = useMemo(() => {
    const itens = [
      {
        label: "Produtos",
        link: "/produtos",
      },
      {
        label: "Informações",
        link: "/informacoes",
      },
    ];

    if (configs.quemSomos) {
      itens.push({
        label: "Quem somos",
        link: "/quem-somos",
      });
    }

    return itens;
  }, [configs.quemSomos]);

  const MenuItens = () => {
    return (
      <div className="itens-container" onClick={() => setMenuOpen(false)}>
        {menuItems.map((item) => (
          <Link key={`menu-item-${item.label}`} to={item.link}>
            {item.label}
          </Link>
        ))}
      </div>
    );
  };

  return (
    <MenuContainer>
      <div className="itens-container desk hide-mobile-900">
        <MenuItens />
      </div>

      <div className="itens-container hide-desktop-900">
        <Collapse activeKey={open} onChange={handleMenuChange}>
          <Panel key="menu">
            <MenuItens />
            <Link
              key={"menu-item-pedidos"}
              to={"/pedidos"}
              onClick={() => setMenuOpen(false)}
            >
              Pedidos
            </Link>
          </Panel>
        </Collapse>

        <MenuOutlined onClick={handleMenuChange} />
      </div>
    </MenuContainer>
  );
}

export default Menu;
